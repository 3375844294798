// Base styles: opinionated defaults
// =============================================================================
html {
	color: $siteCol-blk01;
	background-color: $siteCol-d-blu01;
}

body {
	line-height: 1.7;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		min-width: 320px;
		font-size: $fz15;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		font-size: $fz17;
	}
}

a {
	word-break: break-all;
	.edit_content &,
	.his-sct &,
	.ppr-sct & {
		color: $siteCol-l-blu01;
		font-weight: $fwNsnsJp-bold;
	}
	.edit_content li & {
		.tmp4 & ,
		.tmp8 & {
			display: inline-flex;
			align-items: baseline;
			&::before {
				content: '\e90e';
				margin-right: calc(1em / 1.9);
				font: {
					family: icomoon;
					size: calc(1.9em / 1.7);
					weight: $fwNsrfJp-medium;
				}
				line-height: 1;
				display: block;
				position: relative;
				top: calc(0.2em / 1.9);
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		.edit_content &,
		.his-sct &,
		.ppr-sct & {
			transition: opacity $overTime $easeOutQuint;
			&:hover {
				opacity: 0.4;
			}
		}
	}
}

::-moz-selection {
	@include selectionStyle;
}
::selection {
	@include selectionStyle;
}
