// Utility RSJ classes
// =============================================================================
.w5 { width: 5% !important; }
.w10 { width: 10% !important; }
.w15 { width: 15% !important; }
.w20 { width: 20% !important; }
.w25 { width: 25% !important; }
.w30 { width: 30% !important; }
.w35 { width: 35% !important; }
.w40 { width: 40% !important; }
.w45 { width: 45% !important; }
.w50 { width: 50% !important; }
.w55 { width: 55% !important; }
.w60 { width: 60% !important; }
.w65 { width: 65% !important; }
.w70 { width: 70% !important; }
.w75 { width: 75% !important; }
.w80 { width: 80% !important; }
.w85 { width: 85% !important; }
.w90 { width: 90% !important; }
.w95 { width: 95% !important; }
.w100 { width: 100% !important; }

.mceContentBody {
	padding: 1.5em 2em;
	&.cnt-sub {
		h2, h3 {
			color: $siteCol-blu01;
		}
		h2 {
			font-size: 2.9 / 1.7 + 0em;
		}
		h3 {
			font-size: 2.3 / 1.7 + 0em;
		}
		* + p {
			margin-top: 0.5em;
		}
		ul, ol {
			counter-reset: counter-li;
			&:not(.tab-lst):not(.tag-lst) {
				& > li {
					position: relative;
					counter-increment: counter-li;
					&:nth-child(n+2) {
						margin-top: 0.5em;
					}
					&::before {
						display: inline-block;
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
		ul {
			&:not(.tab-lst):not(.tag-lst) {
				& > li {
					padding-left: 1em;
					&::before {
						content: '・';
					}
				}
			}
		}
		ol {
			&:not(.tab-lst):not(.tag-lst) {
				& > li {
					$base-pdng-l: '4.5em / 1.7';
					padding-left: calc(#{$base-pdng-l});
					&::before {
						content: counter(counter-li)'．';
						width: calc(#{$base-pdng-l});
						text-align: right;
					}
				}
			}
		}
		* + ul,
		* + ol {
			&:not(.tab-lst):not(.tag-lst) {
				margin-top: 0.5em;
			}
		}
		.list_item {
			&:nth-child(n+2) {
				margin-top: calc(3em / 1.7);
				padding-top: calc(3em / 1.7);
				border-top: solid 2px rgba($siteCol-blk01, 0.2);
			}
			h2 {
				margin-right: auto;
				font-size: calc(2.3em / 1.7);
			}
			time {
				font-size: 1.9 / 2.5 + 0em;
				display: block;
			}
			& > div {
				&:first-child {
					display: flex;
					align-items: center;
				}
			}
		}
		.intrdct {
			border-radius: calc(0.5em / 1.7);
			background-color: $siteCol-f-wht;
			&_head {
				display: flex;
				align-items: center;
			}
			&_ttl {
				margin-right: auto;
				color: inherit !important;
				font-size: calc(2.3em / 1.7) !important;
				cursor: pointer;
			}
			&_acrd {
				display: none;
			}
			ol {
				margin-top: calc(3.5em / 1.5) !important;
				padding: calc(2em / 1.5);
				font-size: calc(1.5em / 1.7);
				border-radius: calc(0.5em / 1.5);
				background-color: rgba($siteCol-gly05, 0.25);
				& > li {
					$base-pdng-l: '2.2em / 1.7';
					padding-left: calc(#{$base-pdng-l} + 1.3em / 1.7) !important;
					&::before {
						content: '('counter(counter-li)')' !important;
						width: calc(#{$base-pdng-l}) !important;
					}
				}
			}
		}
	}
	&.edit_content {
		h2, h3, h4, h5, h6 {
			margin: 0;
			padding: 0;
			color: $siteCol-blu01;
			border: 0;
			background-color: transparent;
			position: relative;
		}
		h2 {
			@at-root {
				.tmp11#{&},
				.tmp21#{&} {
					font-size: calc(2.9em / 1.7);
					display: flex;
					align-items: center;
					&::before,
					&::after {
						background-color: $siteCol-l-blu01;
						display: block;
					}
					&::before {
						content: '';
						width: calc(1.2em / 2.9);
						height: calc(1.6em / 2.9);
						margin-right: calc(1.4em / 2.9);
						box-shadow: 0.4 / 2.9 + 0em 0.4 / 2.9 + 0em 0 0 #2c2c79;
						flex-shrink: 0;
						align-self: flex-start;
					}
					&::after {
						content: '';
						height: calc(1.2em / 2.9);
						margin: 0 calc(1em / 2.9) 0 calc(1.4em / 2.9);
						box-shadow: 1 / 2.9 + 0em 0.6 / 2.9 + 0em 0 0 #2c2c79;
						flex-grow: 1;
						position: relative;
						top: calc(-0.3em / 2.9);
					}
					& span {
						display: block;
					}
				}
			}
		}
		* + h2 {
			@at-root {
				.tmp11#{&},
				.tmp21#{&} {
					margin-top: 1em;
				}
			}
		}
		h3 {
			@at-root {
				.tmp11#{&},
				.tmp21#{&} {
					padding-bottom: calc(1em / 2.3);
					font-size: calc(2.3em / 1.7);
					border-bottom: solid 2px $siteCol-l-blu01;
					&::after {
						content: '';
						width: calc(18em / 2.3);
						height: 4px;
						border-radius: 100em;
						background-color: $siteCol-blu01;
						display: block;
						position: absolute;
						bottom: -3px;
						left: 0;
					}
				}
			}
		}
		* + h3 {
			@at-root {
				.tmp11#{&},
				.tmp21#{&} {
					margin-top: 1em;
				}
			}
		}
		h4 {
			@at-root {
				.tmp11#{&},
				.tmp21#{&} {
					padding-left: 0.5em;
					font-size: calc(1.9em / 1.7);
					border-left: solid 5px $siteCol-l-blu01;
				}
				.tmp4#{&} ,
				.tmp8#{&} {
					font-size: calc(2.9em / 1.7);
					display: flex;
					align-items: center;
					&::before,
					&::after {
						background-color: $siteCol-l-blu01;
						display: block;
					}
					&::before {
						content: '';
						width: calc(1.2em / 2.9);
						height: calc(1.6em / 2.9);
						margin-right: calc(1.4em / 2.9);
						box-shadow: 0.4 / 2.9 + 0em 0.4 / 2.9 + 0em 0 0 #2c2c79;
						flex-shrink: 0;
						align-self: flex-start;
					}
					&::after {
						content: '';
						height: calc(1.2em / 2.9);
						margin: 0 calc(1em / 2.9) 0 calc(1.4em / 2.9);
						box-shadow: 1 / 2.9 + 0em 0.6 / 2.9 + 0em 0 0 #2c2c79;
						flex-grow: 1;
						position: relative;
						top: calc(-0.3em / 2.9);
					}
					& span {
						display: block;
					}
				}
			}
		}
		* + h4 {
			@at-root {
				.tmp11#{&},
				.tmp21#{&} {
					margin-top: 1em;
				}
				.tmp4#{&},
				.tmp8#{&} {
					margin-top: calc(5.5em / 2.9);
				}
			}
		}
		h5 {
			@at-root {
				.tmp11#{&},
				.tmp21#{&} {
					font-size: 1.9 / 1.7 + 0em;
					padding-bottom: 0.4em;
					border-bottom: solid 2px rgba($siteCol-gly01, 0.15);
				}
				.tmp4#{&},
				.tmp8#{&} {
					padding-bottom: calc(1em / 2.3);
					font-size: calc(2.3em / 1.7);
					border-bottom: solid 2px $siteCol-l-blu01;
					&::after {
						content: '';
						width: calc(18em / 2.3);
						height: 4px;
						border-radius: 100em;
						background-color: $siteCol-blu01;
						display: block;
						position: absolute;
						bottom: -3px;
						left: 0;
					}
				}
			}
		}
		* + h5 {
			@at-root {
				.tmp11#{&},
				.tmp21#{&} {
					margin-top: 1em;
				}
				.tmp4#{&},
				.tmp8#{&} {
					margin-top: calc(4em / 2.3);
				}
			}
		}
		h6 {
			@at-root {
				.tmp11#{&},
				.tmp21#{&} {
					display: flex;
					align-items: baseline;
					&::before {
						content: '';
						width: 0.8 / 1.7 + 0em;
						height: 3px;
						margin-right: 0.5em;
						background-color: $siteCol-l-blu01;
						display: block;
						flex-shrink: 0;
						transform: translate(0, #{-0.5 / 1.7 + 0em});
					}
				}
			}
		}
		* + h6 {
			@at-root {
				.tmp11#{&},
				.tmp21#{&} {
					margin-top: 1em;
				}
			}
		}
		hr {
			height: 0px;
			border-top: 2px dashed rgba($siteCol-blk01, 0.2);
		}
		* + hr {
			margin: 1em 0;
		}
		p {
			margin: 0;
			font-size: 1em;
		}
		* + p {
			margin-top: 0.5em;
		}
		ul {
			padding: 0;
		}
		@at-root {
			.tmp11#{&},
			.tmp21#{&} {
				ol li {
					padding-left: 3.6 / 1.7 + 0em !important;
				}
			}
		}
		* + ul,
		* + ol {
			margin-top: 0.5em !important;
		}
		li {
			margin: 0;
			padding: 0 !important;
			list-style: none;
			&::before {
				display: none !important;
			}
			@at-root {
				.tmp11#{&},
				.tmp21#{&} {
					margin-top: 0 !important;
					padding-left: 1em !important;
					&::before {
						content: '・';
						display: block !important;
						position: relative;
						top: 0;
						left: 0;
					}
				}
			}
		}
		li a {
			@at-root {
				.tmp4#{&},
				.tmp8#{&} {
					display: inline-flex;
					align-items: baseline;
					&::before {
						content: '\e90e';
						margin-right: calc(1em / 1.9);
						font: {
							family: icomoon;
							size: calc(1.9em / 1.7);
							weight: $fwNsrfJp-medium;
						}
						line-height: 1;
						display: block;
						position: relative;
						top: calc(0.2em / 1.9);
					}
				}
			}
		}
		table {
			margin-top: 0.5em;
			padding: calc(1.5em / 1.7);
			line-height: 1.5;
			background-color: $siteCol-f-wht;
			&, tbody,
			tr, th, td {
				display: block;
			}
			tr, th, td {
				display: flex;
			}
			th, td {
				width: auto;
				padding: 0.5em 0.8em 0.5em 0.5em;
				background-color: $siteCol-gly06;
				align-items: center;
			}
			tr {
				&:nth-child(n+2) {
					margin-top: calc(1em / 1.7);
				}
			}
			img {
				height: auto !important;
				max-width: calc(4em / 1.7);
				min: {
					width: 0;
					height: 0;
				}
				margin: 0 0.8em 0 0;
				border: 0;
				flex-shrink: 0;
			}
		}
	}
	.list_box {
		@at-root {
			.tmp17#{&} {
				margin-top: calc(4.5em / 1.7);
			}
		}
	}
	.list_item {
		@at-root {
			.tmp17#{&} {
				.ttl-box {
					margin-right: auto;
				}
				.dvl-dtl {
					margin-top: calc(0.8em / 1.7);
					font-weight: $fwNsnsJp-bold;
					line-height: 1.5;
					& > div {
						display: flex;
						align-items: flex-start;
					}
					dt {
						display: flex;
						align-items: center;
						&::after {
							content: '';
							width: 2px;
							height: calc(1.4em / 1.7);
							margin-left: calc(1.2em / 1.7);
							background-color: $siteCol-gly01;
							display: block;
						}
					}
					dd {
						margin-left: calc(1.2em / 1.7);
						color: $siteCol-gly01;
						& > span {
							display: inline-block;
							&:nth-last-child(n+2) {
								&::after {
									content: '、';
								}
							}
						}
					}
				}
				._lead {
					margin-top: calc(1.8em / 1.5);
					color: $siteCol-gly01;
					font-size: calc(1.5em / 1.7);
					& + .splt {
						display: none;
					}
				}
			}
		}
	}
}

/*
#ta_ifr,
#tinymce {
	h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
		color: $siteCol-blu01;
		border: 0;
		background-color: transparent;
		position: relative;
	}
	h2 {
		font-size: calc(2.9em / 1.7);
		display: flex;
		align-items: center;
		&::before,
		&::after {
			background-color: $siteCol-l-blu01;
			display: block;
		}
		&::before {
			content: '';
			width: calc(1.2em / 2.9);
			height: calc(1.6em / 2.9);
			margin-right: calc(1.4em / 2.9);
			box-shadow: 0.4 / 2.9 + 0em 0.4 / 2.9 + 0em 0 0 #2c2c79;
			flex-shrink: 0;
			align-self: flex-start;
		}
		&::after {
			content: '';
			height: calc(1.2em / 2.9);
			margin: 0 calc(1em / 2.9) 0 calc(1.4em / 2.9);
			box-shadow: 1 / 2.9 + 0em 0.6 / 2.9 + 0em 0 0 #2c2c79;
			flex-grow: 1;
			position: relative;
			top: calc(-0.3em / 2.9);
		}
		& span {
			display: block;
		}

	}
	* + h2 {
		margin-top: 1em;
	}
	h3 {
		padding-bottom: calc(1em / 2.3);
		font-size: calc(2.3em / 1.7);
		border-bottom: solid 2px $siteCol-l-blu01;
		&::after {
			content: '';
			width: calc(18em / 2.3);
			height: 4px;
			border-radius: 100em;
			background-color: $siteCol-blu01;
			display: block;
			position: absolute;
			bottom: -3px;
			left: 0;
		}
	}
	* + h3 {
		margin-top: 1em;
	}
	h4 {
		padding-left: 0.5em;
		font-size: calc(1.9em / 1.7);
		border-left: solid 5px $siteCol-l-blu01;
	}
	* + h4 {
		margin-top: 1em;
	}
	h5 {
		font-size: 1.9 / 1.7 + 0em;
		padding-bottom: 0.4em;
		border-bottom: solid 2px rgba($siteCol-gly01, 0.15);
	}
	* + h5 {
		margin-top: 1em;
	}
	h6 {
		display: flex;
		align-items: baseline;
		&::before {
			content: '';
			width: 0.8 / 1.7 + 0em;
			height: 3px;
			margin-right: 0.5em;
			background-color: $siteCol-l-blu01;
			display: block;
			flex-shrink: 0;
			transform: translate(0, #{-0.5 / 1.7 + 0em});
		}
	}
	* + h6 {
		margin-top: 1em;
	}
}
*/