// AjaxSearch styles
// =============================================================================
#ajaxSearch {
	&_form {
		width: 100%;
		.srch-otr & {
			@extend %extend--inner-box-1200;
			margin: 0 auto;
		}
		.fxd-nav & {
			max-width: none;
			margin-top: 2 / 1.7 + 0em;
		}
		label {
			display: block;
			margin: 0;
			&:nth-child(2) {
				padding: 0 calc(2em / 1.7);
				border-radius: 100em;
				background-color: $siteCol-f-wht;
				display: flex;
				align-items: center;
				&::before {
					content: '\e904';
					color: $siteCol-blu01;
					font: {
						family: icomoon;
						size: $icn-srch-size-pc;
						weight: $fwNsrfJp-medium;
					}
					line-height: 1;
					display: block;
				}
				.fxd-nav & {
					background-color: $siteCol-gly06;
				}
			}
			&:nth-child(3) {
				width: 1px;
				height: 1px;
				margin: -1px;
				padding: 0;
				border: 0;
				overflow: hidden;
				position: absolute;
				clip: rect(0 0 0 0);
			}
		}
	}
	&_input {
		width: 100%;
		padding: 0;
		color: $siteCol-gly02;
		line-height: 1;
		border: 0;
		background-color: transparent;
		display: block;
		-moz-appearance: none;
		-webkit-appearance: none;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_form {
			label {
				&:nth-child(2) {
					&::before {
						margin-right: calc(1em / 1.6);
						font-size: $icn-srch-size-mbl;
					}
				}
			}
		}
		&_input {
			height: calc(3.2em / 1.6);
			font-size: $fz16;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_form {
			max-width: calc(54em / 1.7);
			label {
				&:nth-child(2) {
					&::before {
						margin-right: calc(1.4em / 2);
						font-size: $icn-srch-size-pc;
					}
				}
			}
			.srch-otr & {
				max-width: none;
			}
		}
		&_input {
			height: calc(3.7em / 1.7);
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		
	}
}

.srch-otr {
	width: 100%;
	padding: 0 30px 1em;
	background-color: $siteCol-gly04;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	top: 100%;
	left: 0;
	transform: translate(0, -100%);
	transition:
		opacity $tglTime * 1.2 $easeOutQuint,
		visibility $tglTime * 1.2 $easeOutQuint,
		transform $tglTime * 1.2 $easeOutQuint;
	&.-is-shw {
		opacity: 1;
		visibility: visible;
		transform: translate(0, 0%);
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: {
			right: 15px;
			left: 15px;
		}
	}
}

.ajaxSearch {
	&_paging {
		margin-top: 3 / 1.7 + 0em;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	&_paging-ttl {
		margin-bottom: 0.6 / 1.9 + 0em;
		color: $siteCol-blu01;
		font: {
			size: 1.9 / 1.7 + 0em;
			weight: $fwNsnsJp-bold;
		}
		line-height: 1.4;
	}
	&_paging-lst {
		margin: -1px;
		color: $siteCol-f-wht;
		font: {
			size: 1.4 / 1.7 + 0em;
			weight: $fwNsnsJp-bold;
		}
		line-height: 1;
		display: flex;
		flex-wrap: wrap;
		& > * {
			width: 2em;
			height: 2em;
			margin: 1px;
			background-color: $siteCol-blu01;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	&_currentPage {
		background-color: $siteCol-gly07;
		opacity: 1 !important;
	}
	&_result {
		margin: 3 / 1.7 + 0em 0 0;
		padding: 0;
		border: 0;
		&:nth-child(n+4) {
			margin-top: 1em;
			padding-top: 1em;
			border-top: solid 2px rgba($siteCol-blk01, 0.2);
		}
	}
	&_resultLink {
		color: $siteCol-blu01 !important;
		font: {
			size: 2.1 / 1.7 + 0em;
			weight: $fwNsnsJp-bold;
		}
		line-height: 1.4;
	}
	&_resultExtract {
		color: $siteCol-gly01;
		font-size: 1.5 / 1.7 + 0em;
		word-wrap: break-word;
	}
	&_highlight {
		color: $siteCol-pnk01;
		font-weight: $fwNsnsJp-bold;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_paging-lst {
			& > * {
				transition: opacity $overTime $easeOutQuint;
				&:hover {
					opacity: 0.4;
				}
			}
		}
	}
}

.doc-srch {
	width: 100%;
	color: $siteCol-blk01;
	.srch-otr & {
		@extend %extend--inner-box-1200;
		margin: 0 auto;
	}
	.fxd-nav & {
		max-width: none;
		margin-top: 2 / 1.7 + 0em;
	}
	&_bdy {
		padding: 0 calc(2em / 1.7);
		border-radius: 100em;
		background-color: $siteCol-f-wht;
		display: flex;
		align-items: center;
		&::before {
			content: '\e904';
			color: $siteCol-blu01;
			font: {
				family: icomoon;
				size: $icn-srch-size-pc;
				weight: $fwNsrfJp-medium;
			}
			line-height: 1;
			display: block;
		}
		.fxd-nav & {
			background-color: $siteCol-gly06;
		}
	}
	&_flx {
		display: flex;
		align-items: center;
		&.-rdo {
			margin-top: 0.8em;
			padding: 0 1em;
		}
		& > * {
			&:nth-child(2) {
				margin-left: 1em;
			}
		}
	}
	&_kw {
		width: 100%;
		padding: 0;
		color: $siteCol-gly02;
		line-height: 1;
		border: 0;
		background-color: transparent;
		display: block;
		-moz-appearance: none;
		-webkit-appearance: none;
	}
	&_rdo-lbl {
		margin: 0;
		line-height: 1;
		display: block;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_bdy {
			&::before {
				margin-right: calc(1em / 1.6);
				font-size: $icn-srch-size-mbl;
			}
		}
		&_kw {
			height: calc(3.2em / 1.6);
			font-size: $fz16;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		max-width: calc(54em / 1.7);
		.srch-otr & {
			max-width: none;
		}
		&_bdy {
			&::before {
				margin-right: calc(1.4em / 2);
				font-size: $icn-srch-size-pc;
			}
		}
		&_kw {
			height: calc(3.7em / 1.7);
		}
	}
}

.rslt-txt {
	margin-top: 1.5em !important;
	line-height: 1.5;
}