@charset "utf-8";

@import "variables";

/* ==============================================================================================
   Reset styles
   ============================================================================================== */
@import "ress";

/* ==============================================================================================
   Base styles: opinionated defaults
   ============================================================================================== */
@import "opinion";

@import "icomoon";

/* ==============================================================================================
   Author's custom styles
   ============================================================================================== */

/* General styles
   ========================================================================== */
.id-num {
	padding: 0.5em;
	color: $siteCol-f-wht;
	font: {
		size: 1.3 / 1.6 ;
		weight: $fwNsnsJp-bold;
	}
	line-height: 1;
	background-color: $siteCol-pnk01;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 10000;
}

@import "gen-logo";

@import "gen-btn";

// @import "gen-icn";

@import "gen-oth";

@import "ajax-search";

@import "extend";

/* Main header styles
   ========================================================================== */
@import "main-head";

/* Main navigation styles
   ========================================================================== */
@import "main-nav";

/* Main footer styles
   ========================================================================== */
@import "main-foot";

/* Main content styles
   ========================================================================== */
.mq-inf {
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		
	}
	@include mediaQueries-maxW('mbl-s') { // mobile size = S //
		
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		
	}
	@include mediaQueries-minW('pc-m') { // desktop size = M //
		
	}
	@include mediaQueries-minW('pc-l') { // desktop size = L //
		
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		
	}
	@include mediaQueries-minW-to-maxW('tblt-m', 'tblt-l') { // tablet size = M - L //
		
	}
}

@import "cnt-gen";

/* Top page styles
   ======================================== */
@import "cnt-top";

/* ==============================================================================================
   Utility classes
   ============================================================================================== */
@import "utility-gen";

@import "utility-rbgk";

/* ==============================================================================================
   Print styles
   ============================================================================================== */
@import "print";
