// Main navigation styles
// =============================================================================
.mn-nav {
	line-height: 1.5;
	background-color: $siteCol-gly04;
	opacity: 1;
	position: relative;
	z-index: 10;
	&_inr {
		@extend %extend--inner-box-1200;
		background-color: $siteCol-gly04;
		display: flex;
		align-items: center;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_inr {
			
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_inr {
			padding-right: calc(3em / 1.7);
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //

	}
}

/* Navigation list styles
   ======================================== */
.nav-lst-otr {
	padding: calc(1.4em / 1.5) 0;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-right: auto;
	}
}

.nav-lst {
	font: {
		size: calc(1.5em / 1.7);
		weight: $fwNsnsJp-bold;
	}
	display: flex;
	align-items: center;
	&_itm {
		flex-shrink: 0;
	}
	&_lnk {
		color: $siteCol-gly01;
		transition: color $overTime $easeOutQuint;
		.is-actv > & {
			color: $siteCol-blu01;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			&:first-child {
				padding-left: calc(2em / 1.3);
			}
			&:last-child {
				padding-right: calc(2em / 1.3);
			}
			&:nth-child(n+2) {
				margin-left: calc(2em / 1.3);
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			&:first-child {
				padding-left: calc(3em / 1.5);
			}
			&:last-child {
				padding-right: calc(3em / 1.5);
			}
			&:nth-child(n+2) {
				margin-left: calc(3em / 1.5);
			}
		}
		&_lnk {
			&:hover {
				color: $siteCol-blu01;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_itm {
			&:nth-last-child(n+2) {
				&::after {
					margin: 0 calc(1.8em / 1.5);
				}
			}
		}
	}
}

/* Fixed navigation styles
   ======================================== */
.fxd-nav-otr {
	width: 100%;
	height: 100%;
	padding: 4 / 1.7 + 0em 0 6 / 1.7 + 0em;
	opacity: 0;
	visibility: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9000;
	transition:
		opacity $navShwTime $easeOutQuint,
		visibility $navShwTime $easeOutQuint,
		transform $navShwTime $easeOutQuint;
	&.is-shw {
		opacity: 1;
		visibility: visible;
	}
	& > * {
		position: relative;
		z-index: 10;
	}
}

.fxd-nav {
	width: 90%;
	padding: {
		top: calc(3em / 1.7);
	}
	border-radius: 1 / 1.7 + 0em;
	background-color: $siteCol-f-wht;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	scrollbar-width: none;
	-ms-overflow-style: none;
	opacity: 0;
	visibility: hidden;
	transform: scale(0.75);
	transition:
		opacity $navShwTime $easeOutQuint,
		visibility $navShwTime $easeOutQuint,
		transform $navShwTime $easeOutQuint;
	&::-webkit-scrollbar {
		display: none;
	}
	&::after {
		content: '';
		width: 100%;
		padding-top: 5 / 1.7 + 0em;
		display: block;
	}
	.is-shw & {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		transition:
			opacity $navShwTime $navShwTime / 2 $easeOutQuint,
			visibility $navShwTime $navShwTime / 2 $easeOutQuint,
			transform $navShwTime $navShwTime / 2 $easeOutQuint;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: {
			right: $mbl-pad;
			left: $mbl-pad;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: {
			right: $pc-pad;
			left: $pc-pad;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		padding: {
			right: $tblt-pad;
			left: $tblt-pad;
		}
	}
}

.overlay {
	width: 100%;
	height: 100%;
	background-color: rgba($siteCol-k-blk, 0.75);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
}

/* Contents list styles
   ======================================== */
.cnt-lst {
	@extend %extend--inner-box-1200;
 	font: {
		size: 1.5 / 1.7 + 0em;
		weight: $fwNsnsJp-bold;
	}
	line-height: 1.5;
	&.-ch-lst {
		max-width: none;
		margin-right: -2 / 1.3 + 0em;
		padding: 0 0 0 2 / 1.3 + 0em;
		font-size: 1.3 / 1.5 + 0em;
		display: flex;
		flex-wrap: wrap;
	}
	.fxd-nav & {
		&:not(.-ch-lst) {
			margin-top: 2.5 / 1.5 + 0em;
		}
	}
	&_lnk {
		color: $siteCol-gly02;
		.-lv01 > & {
			color: $siteCol-blk04;
			line-height: 1.4;
			display: inline-flex;
			align-items: center;
			&::before {
				content: '\e90f';
				margin-right: 0.8 / 1.1 + 0em;
				font: {
					family: icomoon;
					size: 1.1 / 1.5 + 0em;
					weight: $fwNsrfJp-medium;
				}
				line-height: 1;
			}
		}
		.is-actv >  & {
			color: $siteCol-rbgk-blu;
		}
	}
	&_itm {
		&.-lv01 {
			&:nth-child(n+2) {
				margin-top: 2.5 / 1.5 + 0em;
			}
		}
		.-ch-lst & {
			margin: 1 / 1.3 + 0em 2 / 1.3 + 0em 0 0;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_lnk {
			transition: color $overTime $easeOutQuint;
			&:hover {
				color: $siteCol-rbgk-blu;
			}
		}
	}
}

/*
		&::before {
			font: {
				family: icomoon;
				weight: $fwNsrfJp-medium;
			}
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
*/
