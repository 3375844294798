// General other styles
// =============================================================================
.lst-otr {
	display: flex;
	align-items: center;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin: 0 0.5 / 1.5 + 0em 0 1em;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin: 0 2.8 / 1.7 + 0em;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		.tmp12 &, .tmp4 &, .tmp8 &,
		.tmp17 &, .tmp9 &, .tmp10 & {
			flex-direction: column;
			align-items: flex-end;
		}
	}
}

.sns-lst {
	font-size: calc(1.3em / 1.7);
	line-height: 1;
	display: flex;
	align-items: center;
	.hr-head & {
		margin-top: 0 !important;
	}
	&_itm {
		&:nth-child(n+2) {
			margin-left: 1em;
		}
		&::before {
			display: none !important;
		}
		.hr-head & {
			margin-top: 1.5 / 1.3 + 0em !important;
			padding-left: 0 !important;
		}
	}
}

.sub-lnk-lst {
	font: {
		size: 1.3 / 1.7 + 0em;
		weight: $fwNsnsJp-bold;
	}
	line-height: 1.5;
	display: flex;
	align-items: center;
	.fxd-nav & {
		margin: #{2.5 / 1.3 + 0em} #{-1.2 / 1.3 + 0em} #{-0.5 / 1.3 + 0em};
		flex-wrap: wrap;
		justify-content: center;
	}
	&_itm {
		&:nth-child(n+2) {
			margin-left: 2.5 / 1.3 + 0em;
		}
		.fxd-nav & {
			margin: 0.5 / 1.3 + 0em 1.2 / 1.3 + 0em;
		}
	}
	&_lnk {
		color: $siteCol-f-wht !important;
		display: flex;
		align-items: baseline;
		&::before {
			content: '\e90f';
			margin-right: 0.8 / 1.1 + 0em;
			font: {
				family: icomoon;
				size: 1.1 / 1.3 + 0em;
				weight: $fwNsrfJp-medium;
			}
			line-height: 1;
			display: block;
		}
		.fxd-nav & {
			color: $siteCol-blk04 !important;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		.mn-head & {
			display: none;
		}
		.fxd-nav & {
			align-items: flex-start;
			justify-content: flex-start;
			margin: {
				right: -0.5 / 1.3 + 0em;
				left: -0.5 / 1.3 + 0em;
			}
		}
		&_itm {
			.fxd-nav & {
				width: calc((100% - #{(0.5 / 1.3 + 0em) * 4}) / 2);
				margin: {
					right: 0.5 / 1.3 + 0em;
					left: 0.5 / 1.3 + 0em;
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		* + & {
			.mn-head & {
				margin-left: 2.8 / 1.3 + 0em;
			}
		}
		&_lnk {
			transition: opacity $overTime $easeOutQuint;
			&:hover {
				opacity: 0.4;
			}
			.fxd-nav & {
				transition: color $overTime $easeOutQuint;
				&:hover {
					color: $siteCol-rbgk-blu !important;
					opacity: 1;
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		.mn-head & {
			.tmp12 &, .tmp4 &, .tmp8 &,
			.tmp17 &, .tmp9 &, .tmp10 & {
				margin-bottom: 0.5 / 1.3 + 0em;
				order: -1;
			}
		}
	}
}

.lng-lst {
	// margin: 0 2.8 / 1.3 + 0em;
	color: $siteCol-red01;
 	font: {
		size: calc(1.3em / 1.7);
		weight: $fwNsnsJp-bold;
	}
	line-height: 1;
	display: flex;
	align-items: center;
	&_itm {
		display: flex;
		align-items: center;
		&:nth-child(n+2) {
			&::before {
				content: '\e901';
				padding: 0 calc(0.8em / 1.2);
				color: $siteCol-f-wht;
				font: {
					family: icomoon;
					size: calc(1.2em / 1.3);
					weight: $fwNsrfJp-medium;
				}
				line-height: 1;
				display: block;
			}
		}
	}
	&_lnk {
		transition: color $overTime $easeOutQuint;
		&.is-actv {
			color: $siteCol-f-wht;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin: 0;
		&_txt-wrp {
			&::before {
				content: attr(data-lng);
			}
			& > span {
				display: none;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_lnk {
			&.is-actv {
				&:hover {
					color: $siteCol-red01;
				}
			}
		}
	}
}
