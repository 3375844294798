// Main header styles
// =============================================================================
.mn-head {
	background-color: $siteCol-d-blu01;
	&_inr {
		@extend %extend--inner-box-1250;
		padding: {
			top: calc(1.5em / 1.7);
			bottom: calc(1.5em / 1.7);
		}
		display: flex;
		align-items: center;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_inr {
			
		}
	}
}
