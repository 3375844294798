// General logo styles
// =============================================================================
.logo {
	width: 100%;
	line-height: 1;
	display: block;
	&.-rbgk-logo,
	&.-rsj-logo {
		flex-shrink: 0;
	}
	&.-rbgk-logo {
		max-width: 10.8 / 1.7 + 0em;
		margin-right: auto;
		.fxd-nav & {
			max-width: 20 / 1.7 + 0em !important;
			margin: 0 auto;
		}
		.news & {
			max-width: 18 / 1.7 + 0em;
			margin: 0;
		}
		.ua-ie & {
			height: 3.6 / 1.7 + 0em;
		}
	}
	&.-rsj-logo {
		max-width: 20 / 1.7 + 0em;
		.ua-ie & {
			height: 3.4 / 1.7 + 0em;
		}
	}
	&_lnk,
	&_wrp {
		width: 100%;
		height: 100%;
		display: block;
		.-rbgk-logo &,
		.-rsj-logo & {
			color: $siteCol-f-wht;
		}
		.-rbgk-logo & {
			.fxd-nav & {
				color: $siteCol-rbgk-blu;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&.-rbgk-logo {
			flex-shrink: 1;
			.cnt-top & {
				max-width: 14.5 / 1.7 + 0em;
			}
			.cnt-top.ua-ie & {
				height: 4.2 / 1.5 + 0em;
			}
		}
		&.-rsj-logo {
			width: 6.5 / 1.5 + 0em;
			margin-left: 0.8 / 1.5 + 0em;
			overflow: hidden;
			.cnt-top.ua-ie & {
				height: 2.8 / 1.5 + 0em;
			}
		}
		&_lnk {
			.-rsj-logo & {
				width: 16 / 1.5 + 0em;
			}
		}
	}
	@include mediaQueries-maxW('mbl-s') { // mobile size = S //
		&.-rsj-logo {
			.cnt-sub & {
				width: 4.9 / 1.5 + 0em;
			}
			.cnt-top.ua-ie & {
				height: 2.1 / 1.5 + 0em;
			}
		}
		&_lnk {
			.-rsj-logo & {
				.cnt-sub & {
					width: 12 / 1.5 + 0em;
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&.-rbgk-logo {
			.cnt-top & {
				max-width: 21 / 1.7 + 0em;
			}
			.cnt-top.ua-ie & {
				height: 6.9 / 1.7 + 0em;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		
	}
}
