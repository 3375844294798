// Top page styles
// =============================================================================
.news {
	padding: {
		top: 3em;
		bottom: 3em;
	}
	background: {
		color: $siteCol-blu01;
		image: linear-gradient(
			110deg,
			rgba($siteCol-tpp-grd01, 1) 0%,
			rgba($siteCol-tpp-grd02, 1) 25%,
			rgba($siteCol-tpp-grd03, 1) 50%,
			rgba($siteCol-tpp-grd04, 1) 75%,
			rgba($siteCol-tpp-grd05, 1) 100%
		);
	}
	&.-his {
		.cnt-sub & {
			padding: {
				top: 3.5 / 1.7 + 0em;
				bottom: 3.5 / 1.7 + 0em;
			}
			margin-top: 8 / 1.7 + 0em;
		}
	}
	&_ttl {
		margin-bottom: 2 / 2.5 + 0em;
		color: $siteCol-f-wht !important;
		font-size: 2.5 / 1.7 + 0em !important;
		text-align: center;
	}
	&_inr {
		@extend %extend--inner-box-1220;
		display: flex;
		flex-wrap: wrap;
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		display: block;
	}
}

.news-art {
	color: $siteCol-f-wht;
	&_lnk {
		display: block;
		position: relative;
		z-index: 0;
	}
	&_thmb {
		width: calc(100% - 1.5em / 1.7);
		height: calc(14em / 1.7);
		padding: 0 1em;
		color: $siteCol-rbgk-blu;
		background-color: $siteCol-f-wht;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
		}
		&::before {
			width: 100%;
			height: 100%;
			background-color: $siteCol-f-wht;
			opacity: 0;
			top: 0;
			left: 0;
			z-index: 10;
			transition: opacity $overTime $easeOutQuint;
		}
		&::after {
			content: '';
			width: calc(100% - 2.5em / 1.7);
			height: calc(100% - 1em / 1.7);
			background: {
				color: $siteCol-l-blu01;
				image: linear-gradient(
					135deg,
					rgba($siteCol-tpp-grd05, 1) 0%,
					rgba($siteCol-tpp-grd04, 1) 25%,
					rgba($siteCol-tpp-grd03, 1) 50%,
					rgba($siteCol-tpp-grd02, 1) 75%,
					rgba($siteCol-tpp-grd01, 1) 100%
				);
			}
			top: calc(2em / 1.7);
			right: calc(-1.5em / 1.7);
			z-index: -1;
			transform: translate(0, 0);
			transition: transform $overTime $easeOutQuint;
		}
	}
	&_thmb-img {
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}
	}
	&_ttl {
		margin-top: calc(2.5em / 1.5);
		font-size: 1.5 / 1.7 + 0em;
		display: flex;
		flex-direction: column;
		transition: opacity $overTime $easeOutQuint;
		.-his & {
			.cnt-sub & {
				font-size: 1.5 / 1.7 + 0em;
				color: $siteCol-f-wht;
			}
		}
	}
	&_dt {
		margin-bottom: calc(0.5em / 1.3);
		font-size: calc(1.3em / 1.5);
		.-his & {
			display: none;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		width: calc((100% - #{$mbl-pad}) / 2);
		&:nth-child(n+3) {
			margin-top: calc(3.5em / 1.5);
		}
		&:nth-child(even) {
			margin-left: $mbl-pad;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		width: 100%;
		&:nth-child(n+1) {
			margin: 0;
		}
		&:nth-child(n+2) {
			margin-top: calc(4.5em / 1.5);
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		width: calc((100% - #{$tblt-pad} * 2) / 3);
		&:nth-child(n+4) {
			margin-top: $tblt-pad;
		}
		&:nth-child(n+2) {
			margin-left: $tblt-pad;
		}
		&:nth-child(3n+1) {
			margin-left: 0;
		}
		.ua-ie & {
			width: calc(33.33% - #{$tblt-pad});
		}
		&_thmb {
			.news-art_lnk:hover & {
				&::before {
					opacity: 0.65;
				}
				&::after {
					transform: translate((-1.5em / 1.7), (-1 / 1.7 + 0em));
				}
			}
		}
		&_ttl {
			.news-art_lnk:hover & {
				opacity: 0.4;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&,
		.ua-ie & {
			width: calc((100% - #{$tblt-pad}) / 2);
		}
		&:nth-child(n+1) {
			margin: 0;
		}
		&:nth-child(n+3) {
			margin-top: $tblt-pad;
		}
		&:nth-child(even) {
			margin-left: $tblt-pad;
		}
	}
}

.top-pg-sct {
	&_ttl {
		color: $siteCol-blu01;
		font-size: calc(2.5em / 1.7);
		text-align: center;
		display: flex;
		flex-direction: column;
	}
	&_ttl-scnd {
		margin-top: calc(0.5em / 1.5);;
		color: $siteCol-l-blu01;
		font-size: calc(1.5em / 2.5);
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&:nth-child(n+2) {
			margin-top: calc(6em / 1.7)
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		width: calc((100% - #{$pc-pad}) / 2);
		display: flex;
		flex-direction: column;
		align-items: center;
		&:nth-child(n+2) {
			margin-left: $pc-pad;
		}
		&_intr {
			margin-bottom: auto;
			.ua-ie & {
				width: 100%;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		width: calc((100% - #{$tblt-pad}) / 2);
		&:nth-child(n+2) {
			margin-left: $tblt-pad;
		}
	}
}
