@charset "UTF-8";
/* ==============================================================================================
   Reset styles
   ============================================================================================== */
/* Document
   ========================================================================== */
@-ms-viewport {
  width: device-width;
  initial-scale: 1; }

html {
  font-family: "Font Awesome 5 Free", "Roboto", "Noto Sans JP", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", YuGothic, "游ゴシック体", "Yu Gothic", "游ゴシック", Meiryo, "メイリオ", Osaka, "MS PGothic", "ＭＳ Ｐゴシック", sans-serif;
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1;
  text-justify: inter-ideograph;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

/* Sections
   ========================================================================== */
body {
  margin: 0;
  padding: 0;
  font-family: inherit; }

*, *::before, *::after {
  box-sizing: border-box; }

main {
  display: block; }

h1, h2, h3,
h4, h5, h6 {
  margin: 0;
  font-size: inherit;
  font-weight: 700;
  line-height: 1.4; }

/* Grouping content
   ========================================================================== */
address {
  font-style: normal; }

figure {
  margin: 0; }

hr {
  height: 1px;
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden; }

pre {
  font-family: monospace, monospace;
  font-size: inherit; }

/* Links
   ========================================================================== */
a {
  color: currentColor;
  text-decoration: none;
  outline: 0;
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  a:hover, a:focus {
    color: currentColor;
    text-decoration: none; }

/* Text-level semantics
   ========================================================================== */
p {
  margin: 0; }

abbr[title] {
  border-bottom: 0;
  text-decoration: underline dotted; }

b, strong {
  font-weight: 700; }

em {
  font-weight: 700;
  font-style: normal; }

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: inherit; }

small {
  font-size: calc(1.5em / 1.7); }

sup, sub {
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Lists
   ========================================================================== */
dl, ol, ul,
dt, dd, li {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none; }

dt {
  font-weight: 700; }

/* Tables
   ========================================================================== */
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed; }

td, th {
  padding: 0; }

/* Embedded content
   ========================================================================== */
img, svg,
audio, video,
canvas, iframe {
  vertical-align: middle; }

img {
  height: auto;
  max-width: 100%;
  border: 0; }
  img[src$=".svg"] {
    height: 100%; }

svg:not(:root) {
  overflow: hidden; }

/* Forms
   ========================================================================== */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: currentColor;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: dotted 1px ButtonText; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  padding: 0;
  color: inherit;
  white-space: normal;
  box-sizing: border-box;
  display: table; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto;
  resize: vertical; }

[type="checkbox"],
[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

/* Interactive
   ========================================================================== */
details {
  display: block; }

summary {
  display: list-item; }

/* Misc
   ========================================================================== */
template,
[hidden] {
  display: none; }

/* ==============================================================================================
   Base styles: opinionated defaults
   ============================================================================================== */
html {
  color: #333;
  background-color: #0a1d31; }

body {
  line-height: 1.7; }
  @media screen and (max-width: 740px) {
    body {
      min-width: 320px;
      font-size: 1.5rem; } }
  @media screen and (min-width: 741px) {
    body {
      font-size: 1.7rem; } }

a {
  word-break: break-all; }
  .edit_content a,
  .his-sct a,
  .ppr-sct a {
    color: #1c9fca;
    font-weight: 700; }
  .tmp4 .edit_content li a,
  .tmp8 .edit_content li a {
    display: inline-flex;
    align-items: baseline; }
    .tmp4 .edit_content li a::before,
    .tmp8 .edit_content li a::before {
      content: '\e90e';
      margin-right: calc(1em / 1.9);
      font-family: icomoon;
      font-size: calc(1.9em / 1.7);
      font-weight: 500;
      line-height: 1;
      display: block;
      position: relative;
      top: calc(0.2em / 1.9); }
  @media screen and (min-width: 741px) {
    .edit_content a,
    .his-sct a,
    .ppr-sct a {
      transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
      .edit_content a:hover,
      .his-sct a:hover,
      .ppr-sct a:hover {
        opacity: 0.4; } }

::-moz-selection {
  text-shadow: none;
  background-color: #b3d4fc; }

::selection {
  text-shadow: none;
  background-color: #b3d4fc; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.ttf?sjb745") format("truetype"), url("../fonts/icomoon.woff?sjb745") format("woff"), url("../fonts/icomoon.svg?sjb745#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icn-"], [class*=" icn-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icn-splt-hr-m:before {
  content: "\e90e"; }

.icn-splt-hr:before {
  content: "\e90f"; }

.icn-lst:before {
  content: "\e900"; }

.icn-splt:before {
  content: "\e901"; }

.icn-arw:before {
  content: "\e902"; }

.icn-nav:before {
  content: "\e903"; }

.icn-srch:before {
  content: "\e904"; }

.icn-fb:before {
  content: "\e905"; }

.icn-tw:before {
  content: "\e906"; }

.icn-blnk-sld:before {
  content: "\e907"; }

.icn-blnk:before {
  content: "\e908"; }

.icn-wrd-sld:before {
  content: "\e909"; }

.icn-wrd:before {
  content: "\e90a"; }

.icn-pdf-sld:before {
  content: "\e90b"; }

.icn-pdf:before {
  content: "\e90d"; }

.icn-ttp:before {
  content: "\e90c"; }

/* ==============================================================================================
   Author's custom styles
   ============================================================================================== */
/* General styles
   ========================================================================== */
.id-num {
  padding: 0.5em;
  color: #fff;
  font-size: 1.3 / 1.6;
  font-weight: 700;
  line-height: 1;
  background-color: #ca1c51;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000; }

.logo {
  width: 100%;
  line-height: 1;
  display: block; }
  .logo.-rbgk-logo, .logo.-rsj-logo {
    flex-shrink: 0; }
  .logo.-rbgk-logo {
    max-width: 6.35294em;
    margin-right: auto; }
    .fxd-nav .logo.-rbgk-logo {
      max-width: 11.76471em !important;
      margin: 0 auto; }
    .news .logo.-rbgk-logo {
      max-width: 10.58824em;
      margin: 0; }
    .ua-ie .logo.-rbgk-logo {
      height: 2.11765em; }
  .logo.-rsj-logo {
    max-width: 11.76471em; }
    .ua-ie .logo.-rsj-logo {
      height: 2em; }
  .logo_lnk, .logo_wrp {
    width: 100%;
    height: 100%;
    display: block; }
    .-rbgk-logo .logo_lnk,
    .-rsj-logo .logo_lnk, .-rbgk-logo .logo_wrp,
    .-rsj-logo .logo_wrp {
      color: #fff; }
    .fxd-nav .-rbgk-logo .logo_lnk, .fxd-nav .-rbgk-logo .logo_wrp {
      color: #142f85; }
  @media screen and (max-width: 740px) {
    .logo.-rbgk-logo {
      flex-shrink: 1; }
      .cnt-top .logo.-rbgk-logo {
        max-width: 8.52941em; }
      .cnt-top.ua-ie .logo.-rbgk-logo {
        height: 2.8em; }
    .logo.-rsj-logo {
      width: 4.33333em;
      margin-left: 0.53333em;
      overflow: hidden; }
      .cnt-top.ua-ie .logo.-rsj-logo {
        height: 1.86667em; }
    .-rsj-logo .logo_lnk {
      width: 10.66667em; } }
  @media screen and (max-width: 370px) {
    .cnt-sub .logo.-rsj-logo {
      width: 3.26667em; }
    .cnt-top.ua-ie .logo.-rsj-logo {
      height: 1.4em; }
    .cnt-sub .-rsj-logo .logo_lnk {
      width: 8em; } }
  @media screen and (min-width: 741px) {
    .cnt-top .logo.-rbgk-logo {
      max-width: 12.35294em; }
    .cnt-top.ua-ie .logo.-rbgk-logo {
      height: 4.05882em; } }

.btn {
  width: 100%;
  max-width: calc(36em / 1.7);
  margin: 0 auto;
  color: #fff !important;
  font-size: calc(1.5em / 1.7);
  font-weight: 700;
  line-height: 1.5;
  border: 0;
  border-radius: calc(0.3em / 1.7);
  background-color: #043c78;
  display: block; }
  .btn.-sns-btn, .btn.-nav-btn {
    border-radius: 0;
    background-color: transparent; }
  .btn.-sns-btn {
    width: calc(1.7em / 1.3);
    height: calc(1.7em / 1.3);
    padding: 0;
    color: #fff !important;
    font-size: 1.13333em;
    line-height: 0; }
  .btn.-nav-btn {
    max-width: calc(4.5em / 1.1);
    margin: 0 0 0 calc(2em / 1.1);
    padding: 0;
    color: #043c78 !important;
    font-size: calc(1.1em / 1.7);
    font-weight: 900;
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .btn.-nav-btn::before {
      font-family: icomoon;
      font-weight: 500;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center; }
    .mn-head .btn.-nav-btn {
      color: #fff !important; }
  .btn.-srch-btn::before {
    height: calc(2.2em / 2);
    content: '\e904';
    font-size: calc(2em / 1.1); }
  .btn.-menu-btn::before {
    height: calc(2.2em / 3.2);
    content: '\e903';
    font-size: calc(3.2em / 1.1); }
  .btn.-cls-btn {
    max-width: 13.33333em;
    margin-top: 0.66667em;
    padding-top: 3px;
    padding-bottom: 3px; }
    .fxd-bnt-otr .btn.-cls-btn {
      width: 13.33333em;
      margin-top: 2em;
      padding-top: 0.4em;
      padding-bottom: 0.4em;
      background-color: #ca1c51; }
  .news .btn.-lst-btn {
    max-width: 22em;
    margin-top: calc(6em / 1.7);
    background-color: #ca1c51; }
  .top-pg-sct .btn.-lst-btn {
    margin-top: calc(3em / 1.7); }
  .btn_lnk, .btn_wrp {
    width: 100%;
    height: 100%;
    padding: calc(1.25em / 1.7) 1em;
    display: flex;
    align-items: center;
    justify-content: center; }
    .news .-lst-btn .btn_lnk, .news .-lst-btn .btn_wrp {
      padding: calc(0.75em / 1.7) 1em; }
  .-sns-btn .btn_lnk {
    padding: 0;
    display: block; }
  .-lst-btn .btn_lnk::before {
    content: '\e900';
    margin-right: calc(1.7em / 1.4);
    font-family: icomoon;
    font-size: calc(1.4em / 1.7);
    font-weight: 500;
    line-height: 1;
    display: block;
    flex-shrink: 0; }
  .-nav-btn .btn_wrp {
    margin-top: calc(0.3em / 1.1);
    padding: 0; }
  @media screen and (max-width: 740px) {
    .btn.-nav-btn {
      margin-left: 0.8em; }
      .mn-nav .btn.-nav-btn {
        display: none; } }
  @media screen and (min-width: 741px) {
    .btn {
      transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
      .btn:hover {
        opacity: 0.4; }
      .mn-head .btn.-nav-btn {
        display: none; } }

.tag {
  color: #043c78;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1;
  border-radius: calc(0.2em / 1.3);
  background-color: #fff;
  display: block; }
  .news .tag {
    color: #043c78 !important;
    background-color: #fff !important; }
  .mn-art .tag {
    color: #fff;
    background-color: #043c78; }
  .hr-head .tag {
    color: #043c78;
    background-color: #fff; }
  .srch-asd .tag {
    border-radius: 100em; }
  .tag_lnk {
    width: 100%;
    height: 100%;
    padding: calc(0.4em / 1.3) calc(0.6em / 1.3);
    display: flex;
    align-items: center; }
    .srch-asd .tag_lnk {
      padding: calc(0.35em / 1.3) calc(1.2em / 1.3); }
    .tag_lnk::before {
      content: '';
      width: calc(0.6em / 1.3);
      height: calc(0.6em / 1.3);
      margin-right: calc(0.4em / 1.3);
      border-radius: 100em;
      background-color: currentColor;
      display: block;
      flex-shrink: 0; }
      .srch-asd .tag_lnk::before {
        content: '\e902';
        width: auto;
        height: auto;
        margin-right: calc(0.6em / 1.3);
        font-family: icomoon;
        font-size: calc(1.6em / 1.3);
        font-weight: 500;
        line-height: 1;
        border-radius: 0;
        background-color: transparent; }
  @media screen and (min-width: 741px) {
    .tag {
      transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
      .tag:hover {
        opacity: 0.4; } }

.tag-lst {
  margin: calc(-0.4em / 1.3);
  font-size: 1.3rem;
  display: flex;
  flex-wrap: wrap; }
  .news .tag-lst {
    margin-top: calc(1.2em / 1.3); }
  .hr-head .tag-lst {
    margin-top: 0.61538em; }
  .list_item .tag-lst {
    margin-top: calc(1.6em / 1.3); }
  .srch-asd .tag-lst {
    margin: calc(-0.6em / 1.3);
    justify-content: center; }
  .tag-lst_itm {
    margin: calc(0.4em / 1.3); }
    .srch-asd .tag-lst_itm {
      margin: calc(0.6em / 1.3); }

.more {
  color: #fff;
  font-size: calc(1.5em / 1.7);
  font-weight: 700;
  line-height: 1.4;
  border-radius: 100em;
  background-color: #043c78;
  overflow: hidden;
  display: inline-flex; }
  .list_item .more {
    margin-left: 1em;
    flex-shrink: 0; }
  .more a {
    width: 100%;
    height: 100%;
    padding: calc(0.4em / 1.5) 1em;
    display: flex;
    align-items: center; }
    .more a::after {
      content: '\e902';
      margin-left: calc(1.8em / 1.6);
      font-family: icomoon;
      font-size: calc(1.6em / 1.5);
      font-weight: 500;
      line-height: 1;
      display: block;
      flex-shrink: 0; }
  @media screen and (max-width: 480px) {
    .list_item .more {
      width: 136px;
      margin: 0.6em 0 0; } }
  @media screen and (min-width: 741px) {
    .more {
      transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
      .more:hover {
        opacity: 0.4; } }

.tgl {
  padding: calc(0.25em / 1.5) 1em;
  color: #fff;
  font-size: calc(1.5em / 1.7);
  font-weight: 700;
  font-size: calc(1.5em / 1.7);
  line-height: 1.4;
  border-radius: 100em;
  background-color: #043c78;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer; }
  .intrdct .tgl {
    margin-left: calc(2em / 1.5); }
  .is-shw .tgl::after {
    content: attr(data-tgl); }
  .tgl_icn {
    width: calc(1.3em / 1.5);
    height: calc(0.3em / 1.5);
    margin-right: calc(1em / 1.5);
    line-height: 1;
    background-color: currentColor;
    display: block;
    flex-shrink: 0;
    position: relative; }
    .tgl_icn::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: inherit;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(-90deg);
      transition: transform 250ms cubic-bezier(0.23, 1, 0.32, 1); }
      .is-shw .tgl_icn::before {
        transform: rotate(0); }
  .is-shw .tgl_txt {
    display: none; }
  @media screen and (max-width: 425px) {
    .intrdct .tgl {
      width: 75%;
      margin: calc(2em / 1.5) auto 0;
      display: flex;
      justify-content: center; } }
  @media screen and (min-width: 741px) {
    .tgl {
      transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
      .tgl:hover {
        opacity: 0.4; } }

.lst-otr {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 740px) {
    .lst-otr {
      margin: 0 0.33333em 0 1em; } }
  @media screen and (min-width: 741px) {
    .lst-otr {
      margin: 0 1.64706em; } }
  @media screen and (min-width: 741px) and (max-width: 880px) {
    .tmp12 .lst-otr, .tmp4 .lst-otr, .tmp8 .lst-otr,
    .tmp17 .lst-otr, .tmp9 .lst-otr, .tmp10 .lst-otr {
      flex-direction: column;
      align-items: flex-end; } }

.sns-lst {
  font-size: calc(1.3em / 1.7);
  line-height: 1;
  display: flex;
  align-items: center; }
  .hr-head .sns-lst {
    margin-top: 0 !important; }
  .sns-lst_itm:nth-child(n+2) {
    margin-left: 1em; }
  .sns-lst_itm::before {
    display: none !important; }
  .hr-head .sns-lst_itm {
    margin-top: 1.15385em !important;
    padding-left: 0 !important; }

.sub-lnk-lst {
  font-size: 0.76471em;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  align-items: center; }
  .fxd-nav .sub-lnk-lst {
    margin: 1.92308em -0.92308em -0.38462em;
    flex-wrap: wrap;
    justify-content: center; }
  .sub-lnk-lst_itm:nth-child(n+2) {
    margin-left: 1.92308em; }
  .fxd-nav .sub-lnk-lst_itm {
    margin: 0.38462em 0.92308em; }
  .sub-lnk-lst_lnk {
    color: #fff !important;
    display: flex;
    align-items: baseline; }
    .sub-lnk-lst_lnk::before {
      content: '\e90f';
      margin-right: 0.72727em;
      font-family: icomoon;
      font-size: 0.84615em;
      font-weight: 500;
      line-height: 1;
      display: block; }
    .fxd-nav .sub-lnk-lst_lnk {
      color: #555 !important; }
  @media screen and (max-width: 740px) {
    .mn-head .sub-lnk-lst {
      display: none; }
    .fxd-nav .sub-lnk-lst {
      align-items: flex-start;
      justify-content: flex-start;
      margin-right: -0.38462em;
      margin-left: -0.38462em; }
    .fxd-nav .sub-lnk-lst_itm {
      width: calc((100% - 1.53846em) / 2);
      margin-right: 0.38462em;
      margin-left: 0.38462em; } }
  @media screen and (min-width: 741px) {
    .mn-head * + .sub-lnk-lst {
      margin-left: 2.15385em; }
    .sub-lnk-lst_lnk {
      transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
      .sub-lnk-lst_lnk:hover {
        opacity: 0.4; }
      .fxd-nav .sub-lnk-lst_lnk {
        transition: color 400ms cubic-bezier(0.23, 1, 0.32, 1); }
        .fxd-nav .sub-lnk-lst_lnk:hover {
          color: #142f85 !important;
          opacity: 1; } }
  @media screen and (min-width: 741px) and (max-width: 880px) {
    .tmp12 .mn-head .sub-lnk-lst, .tmp4 .mn-head .sub-lnk-lst, .tmp8 .mn-head .sub-lnk-lst,
    .tmp17 .mn-head .sub-lnk-lst, .tmp9 .mn-head .sub-lnk-lst, .tmp10 .mn-head .sub-lnk-lst {
      margin-bottom: 0.38462em;
      order: -1; } }

.lng-lst {
  color: #f7373e;
  font-size: calc(1.3em / 1.7);
  font-weight: 700;
  line-height: 1;
  display: flex;
  align-items: center; }
  .lng-lst_itm {
    display: flex;
    align-items: center; }
    .lng-lst_itm:nth-child(n+2)::before {
      content: '\e901';
      padding: 0 calc(0.8em / 1.2);
      color: #fff;
      font-family: icomoon;
      font-size: calc(1.2em / 1.3);
      font-weight: 500;
      line-height: 1;
      display: block; }
  .lng-lst_lnk {
    transition: color 400ms cubic-bezier(0.23, 1, 0.32, 1); }
    .lng-lst_lnk.is-actv {
      color: #fff; }
  @media screen and (max-width: 740px) {
    .lng-lst {
      margin: 0; }
      .lng-lst_txt-wrp::before {
        content: attr(data-lng); }
      .lng-lst_txt-wrp > span {
        display: none; } }
  @media screen and (min-width: 741px) {
    .lng-lst_lnk.is-actv:hover {
      color: #f7373e; } }

#ajaxSearch_form {
  width: 100%; }
  .srch-otr #ajaxSearch_form {
    margin: 0 auto; }
  .fxd-nav #ajaxSearch_form {
    max-width: none;
    margin-top: 1.17647em; }
  #ajaxSearch_form label {
    display: block;
    margin: 0; }
    #ajaxSearch_form label:nth-child(2) {
      padding: 0 calc(2em / 1.7);
      border-radius: 100em;
      background-color: #fff;
      display: flex;
      align-items: center; }
      #ajaxSearch_form label:nth-child(2)::before {
        content: '\e904';
        color: #043c78;
        font-family: icomoon;
        font-size: calc(2em / 1.7);
        font-weight: 500;
        line-height: 1;
        display: block; }
      .fxd-nav #ajaxSearch_form label:nth-child(2) {
        background-color: #eee; }
    #ajaxSearch_form label:nth-child(3) {
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
      overflow: hidden;
      position: absolute;
      clip: rect(0 0 0 0); }

#ajaxSearch_input {
  width: 100%;
  padding: 0;
  color: #999;
  line-height: 1;
  border: 0;
  background-color: transparent;
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none; }

@media screen and (max-width: 740px) {
  #ajaxSearch_form label:nth-child(2)::before {
    margin-right: calc(1em / 1.6);
    font-size: calc(1.6em / 1.5); }
  #ajaxSearch_input {
    height: calc(3.2em / 1.6);
    font-size: 1.6rem; } }

@media screen and (min-width: 741px) {
  #ajaxSearch_form {
    max-width: calc(54em / 1.7); }
    #ajaxSearch_form label:nth-child(2)::before {
      margin-right: calc(1.4em / 2);
      font-size: calc(2em / 1.7); }
    .srch-otr #ajaxSearch_form {
      max-width: none; }
  #ajaxSearch_input {
    height: calc(3.7em / 1.7); } }

.srch-otr {
  width: 100%;
  padding: 0 30px 1em;
  background-color: #e4eaea;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  transform: translate(0, -100%);
  transition: opacity 300ms cubic-bezier(0.23, 1, 0.32, 1), visibility 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1); }
  .srch-otr.-is-shw {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0%); }
  @media screen and (max-width: 740px) {
    .srch-otr {
      padding-right: 15px;
      padding-left: 15px; } }

.ajaxSearch_paging {
  margin-top: 1.76471em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.ajaxSearch_paging-ttl {
  margin-bottom: 0.31579em;
  color: #043c78;
  font-size: 1.11765em;
  font-weight: 700;
  line-height: 1.4; }

.ajaxSearch_paging-lst {
  margin: -1px;
  color: #fff;
  font-size: 0.82353em;
  font-weight: 700;
  line-height: 1;
  display: flex;
  flex-wrap: wrap; }
  .ajaxSearch_paging-lst > * {
    width: 2em;
    height: 2em;
    margin: 1px;
    background-color: #043c78;
    display: flex;
    align-items: center;
    justify-content: center; }

.ajaxSearch_currentPage {
  background-color: #ccc;
  opacity: 1 !important; }

.ajaxSearch_result {
  margin: 1.76471em 0 0;
  padding: 0;
  border: 0; }
  .ajaxSearch_result:nth-child(n+4) {
    margin-top: 1em;
    padding-top: 1em;
    border-top: solid 2px rgba(51, 51, 51, 0.2); }

.ajaxSearch_resultLink {
  color: #043c78 !important;
  font-size: 1.23529em;
  font-weight: 700;
  line-height: 1.4; }

.ajaxSearch_resultExtract {
  color: #666;
  font-size: 0.88235em;
  word-wrap: break-word; }

.ajaxSearch_highlight {
  color: #ca1c51;
  font-weight: 700; }

@media screen and (min-width: 741px) {
  .ajaxSearch_paging-lst > * {
    transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
    .ajaxSearch_paging-lst > *:hover {
      opacity: 0.4; } }

.doc-srch {
  width: 100%;
  color: #333; }
  .srch-otr .doc-srch {
    margin: 0 auto; }
  .fxd-nav .doc-srch {
    max-width: none;
    margin-top: 1.17647em; }
  .doc-srch_bdy {
    padding: 0 calc(2em / 1.7);
    border-radius: 100em;
    background-color: #fff;
    display: flex;
    align-items: center; }
    .doc-srch_bdy::before {
      content: '\e904';
      color: #043c78;
      font-family: icomoon;
      font-size: calc(2em / 1.7);
      font-weight: 500;
      line-height: 1;
      display: block; }
    .fxd-nav .doc-srch_bdy {
      background-color: #eee; }
  .doc-srch_flx {
    display: flex;
    align-items: center; }
    .doc-srch_flx.-rdo {
      margin-top: 0.8em;
      padding: 0 1em; }
    .doc-srch_flx > *:nth-child(2) {
      margin-left: 1em; }
  .doc-srch_kw {
    width: 100%;
    padding: 0;
    color: #999;
    line-height: 1;
    border: 0;
    background-color: transparent;
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none; }
  .doc-srch_rdo-lbl {
    margin: 0;
    line-height: 1;
    display: block; }
  @media screen and (max-width: 740px) {
    .doc-srch_bdy::before {
      margin-right: calc(1em / 1.6);
      font-size: calc(1.6em / 1.5); }
    .doc-srch_kw {
      height: calc(3.2em / 1.6);
      font-size: 1.6rem; } }
  @media screen and (min-width: 741px) {
    .doc-srch {
      max-width: calc(54em / 1.7); }
      .srch-otr .doc-srch {
        max-width: none; }
      .doc-srch_bdy::before {
        margin-right: calc(1.4em / 2);
        font-size: calc(2em / 1.7); }
      .doc-srch_kw {
        height: calc(3.7em / 1.7); } }

.rslt-txt {
  margin-top: 1.5em !important;
  line-height: 1.5; }

.wrd-srch {
  max-width: 960px;
  margin: 0 auto; }

.mn-foot_inr, .srch-asd_inr {
  max-width: 1120px;
  margin: 0 auto; }

.srch-otr #ajaxSearch_form, .srch-otr .doc-srch, .mn-nav_inr, .cnt-lst {
  max-width: 1200px;
  margin: 0 auto; }

.mn-art_inr, .hr-head_inr, .tab, .news_inr {
  max-width: 1220px;
  margin: 0 auto; }

.mn-head_inr {
  max-width: 1250px;
  margin: 0 auto; }

@media screen and (max-width: 740px) {
  .mn-foot_inr, .srch-asd_inr, .mn-art_inr, .hr-head_inr, .tab, .news_inr {
    padding-right: 20px;
    padding-left: 20px; }
  .mn-head_inr {
    padding-right: 12px;
    padding-left: 12px; } }

@media screen and (min-width: 741px) {
  .mn-foot_inr, .srch-asd_inr, .mn-art_inr, .hr-head_inr, .tab, .news_inr {
    padding-right: 60px;
    padding-left: 60px; }
  .mn-head_inr {
    padding-right: 25px;
    padding-left: 25px; } }

@media screen and (min-width: 741px) and (max-width: 1050px) {
  .mn-foot_inr, .srch-asd_inr, .mn-art_inr, .hr-head_inr, .tab, .news_inr {
    padding-right: 40px;
    padding-left: 40px; } }

/* Main header styles
   ========================================================================== */
.mn-head {
  background-color: #0a1d31; }
  .mn-head_inr {
    padding-top: calc(1.5em / 1.7);
    padding-bottom: calc(1.5em / 1.7);
    display: flex;
    align-items: center; }

/* Main navigation styles
   ========================================================================== */
.mn-nav {
  line-height: 1.5;
  background-color: #e4eaea;
  opacity: 1;
  position: relative;
  z-index: 10; }
  .mn-nav_inr {
    background-color: #e4eaea;
    display: flex;
    align-items: center; }
  @media screen and (min-width: 741px) {
    .mn-nav_inr {
      padding-right: calc(3em / 1.7); } }

/* Navigation list styles
   ======================================== */
.nav-lst-otr {
  padding: calc(1.4em / 1.5) 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none; }
  .nav-lst-otr::-webkit-scrollbar {
    display: none; }
  @media screen and (min-width: 741px) {
    .nav-lst-otr {
      margin-right: auto; } }

.nav-lst {
  font-size: calc(1.5em / 1.7);
  font-weight: 700;
  display: flex;
  align-items: center; }
  .nav-lst_itm {
    flex-shrink: 0; }
  .nav-lst_lnk {
    color: #666;
    transition: color 400ms cubic-bezier(0.23, 1, 0.32, 1); }
    .is-actv > .nav-lst_lnk {
      color: #043c78; }
  @media screen and (max-width: 740px) {
    .nav-lst_itm:first-child {
      padding-left: calc(2em / 1.3); }
    .nav-lst_itm:last-child {
      padding-right: calc(2em / 1.3); }
    .nav-lst_itm:nth-child(n+2) {
      margin-left: calc(2em / 1.3); } }
  @media screen and (min-width: 741px) {
    .nav-lst_itm:first-child {
      padding-left: calc(3em / 1.5); }
    .nav-lst_itm:last-child {
      padding-right: calc(3em / 1.5); }
    .nav-lst_itm:nth-child(n+2) {
      margin-left: calc(3em / 1.5); }
    .nav-lst_lnk:hover {
      color: #043c78; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .nav-lst_itm:nth-last-child(n+2)::after {
      margin: 0 calc(1.8em / 1.5); } }

/* Fixed navigation styles
   ======================================== */
.fxd-nav-otr {
  width: 100%;
  height: 100%;
  padding: 2.35294em 0 3.52941em;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1), visibility 500ms cubic-bezier(0.23, 1, 0.32, 1), transform 500ms cubic-bezier(0.23, 1, 0.32, 1); }
  .fxd-nav-otr.is-shw {
    opacity: 1;
    visibility: visible; }
  .fxd-nav-otr > * {
    position: relative;
    z-index: 10; }

.fxd-nav {
  width: 90%;
  padding-top: calc(3em / 1.7);
  border-radius: 0.58824em;
  background-color: #fff;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.75);
  transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1), visibility 500ms cubic-bezier(0.23, 1, 0.32, 1), transform 500ms cubic-bezier(0.23, 1, 0.32, 1); }
  .fxd-nav::-webkit-scrollbar {
    display: none; }
  .fxd-nav::after {
    content: '';
    width: 100%;
    padding-top: 2.94118em;
    display: block; }
  .is-shw .fxd-nav {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: opacity 500ms 250ms cubic-bezier(0.23, 1, 0.32, 1), visibility 500ms 250ms cubic-bezier(0.23, 1, 0.32, 1), transform 500ms 250ms cubic-bezier(0.23, 1, 0.32, 1); }
  @media screen and (max-width: 740px) {
    .fxd-nav {
      padding-right: 20px;
      padding-left: 20px; } }
  @media screen and (min-width: 741px) {
    .fxd-nav {
      padding-right: 60px;
      padding-left: 60px; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .fxd-nav {
      padding-right: 40px;
      padding-left: 40px; } }

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5; }

/* Contents list styles
   ======================================== */
.cnt-lst {
  font-size: 0.88235em;
  font-weight: 700;
  line-height: 1.5; }
  .cnt-lst.-ch-lst {
    max-width: none;
    margin-right: -1.53846em;
    padding: 0 0 0 1.53846em;
    font-size: 0.86667em;
    display: flex;
    flex-wrap: wrap; }
  .fxd-nav .cnt-lst:not(.-ch-lst) {
    margin-top: 1.66667em; }
  .cnt-lst_lnk {
    color: #999; }
    .-lv01 > .cnt-lst_lnk {
      color: #555;
      line-height: 1.4;
      display: inline-flex;
      align-items: center; }
      .-lv01 > .cnt-lst_lnk::before {
        content: '\e90f';
        margin-right: 0.72727em;
        font-family: icomoon;
        font-size: 0.73333em;
        font-weight: 500;
        line-height: 1; }
    .is-actv > .cnt-lst_lnk {
      color: #142f85; }
  .cnt-lst_itm.-lv01:nth-child(n+2) {
    margin-top: 1.66667em; }
  .-ch-lst .cnt-lst_itm {
    margin: 0.76923em 1.53846em 0 0; }
  @media screen and (min-width: 741px) {
    .cnt-lst_lnk {
      transition: color 400ms cubic-bezier(0.23, 1, 0.32, 1); }
      .cnt-lst_lnk:hover {
        color: #142f85; } }

/*
		&::before {
			font: {
				family: icomoon;
				weight: $fwNsrfJp-medium;
			}
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
*/
/* Main footer styles
   ========================================================================== */
.mn-foot {
  line-height: 1.5;
  background-color: #0a1d31; }
  .mn-foot_inr.-cr {
    height: 5.88235em;
    display: flex;
    align-items: center; }
  @media screen and (max-width: 740px) {
    .mn-foot_inr.-cr {
      flex-direction: column;
      justify-content: center; } }

.srch-asd {
  padding-top: calc(3.5em / 1.7);
  color: #fff; }
  .srch-asd_ttl {
    margin-bottom: calc(2em / 2.5);
    font-size: calc(2.5em / 1.7);
    text-align: center; }
    .wrd-srch .srch-asd_ttl {
      color: #666;
      flex-shrink: 0; }
  @media screen and (min-width: 741px) {
    .wrd-srch .srch-asd_ttl {
      margin: 0 calc(3em / 2.5) 0 0; } }

.wrd-srch {
  margin-top: calc(4em / 1.7);
  background-color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5; }
  @media screen and (max-width: 740px) {
    .wrd-srch {
      margin-bottom: -64px;
      padding: calc(2em / 1.5) 20px calc(2.8em / 1.5);
      flex-direction: column; } }
  @media screen and (min-width: 741px) {
    .wrd-srch {
      margin-bottom: -50px;
      padding: calc(3.15em / 1.7) calc(3em / 1.7); } }

.ptt {
  color: #1c9fca;
  font-size: 0.76471em;
  font-weight: 900;
  text-align: center;
  background-color: #e2f9ff; }
  .ptt_lnk {
    height: calc(7.5em / 1.3);
    display: flex;
    align-items: center;
    justify-content: center; }
  .ptt_lnk-txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .ptt_lnk-txt::before {
      content: '\e90c';
      margin-bottom: calc(0.2em / 1.8);
      font-family: icomoon;
      font-size: calc(1.8em / 1.3);
      font-weight: 500;
      line-height: 1;
      display: block; }
  @media screen and (max-width: 740px) {
    .ptt {
      padding-top: 64px; } }
  @media screen and (min-width: 741px) {
    .ptt {
      padding-top: 50px; } }

.cr-txt {
  font-size: 0.64706em;
  font-weight: 700;
  text-align: center;
  /*
	display: flex;
	align-items: center;
	justify-content: center;
*/ }
  .cr-txt_lnk {
    color: #fff !important; }
  @media screen and (max-width: 740px) {
    .cr-txt {
      margin-top: 1em; } }
  @media screen and (min-width: 741px) {
    .cr-txt {
      margin-right: auto;
      order: -1; } }

/* Main content styles
   ========================================================================== */
.mn-cnt {
  background-color: #fff;
  background-image: url(../img/img_bg_noise.png);
  background-position: left top;
  background-size: 50px 50px; }
  .cnt-sub .mn-cnt h2, .cnt-sub .mn-cnt h3 {
    color: #043c78; }
  .cnt-sub .mn-cnt h2 {
    font-size: 1.70588em; }
  .cnt-sub .mn-cnt h3 {
    font-size: 1.35294em; }
  .cnt-sub .mn-cnt * + p {
    margin-top: 0.5em; }
  .cnt-sub .mn-cnt ul, .cnt-sub .mn-cnt ol {
    counter-reset: counter-li; }
    .cnt-sub .mn-cnt ul:not(.tab-lst):not(.tag-lst) > li, .cnt-sub .mn-cnt ol:not(.tab-lst):not(.tag-lst) > li {
      position: relative;
      counter-increment: counter-li; }
      .cnt-sub .mn-cnt ul:not(.tab-lst):not(.tag-lst) > li:nth-child(n+2), .cnt-sub .mn-cnt ol:not(.tab-lst):not(.tag-lst) > li:nth-child(n+2) {
        margin-top: 0.5em; }
      .cnt-sub .mn-cnt ul:not(.tab-lst):not(.tag-lst) > li::before, .cnt-sub .mn-cnt ol:not(.tab-lst):not(.tag-lst) > li::before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0; }
      .cnt-sub .mn-cnt ul:not(.tab-lst):not(.tag-lst) > li[data-lst-styl]::before, .cnt-sub .mn-cnt ol:not(.tab-lst):not(.tag-lst) > li[data-lst-styl]::before {
        content: attr(data-lst-styl); }
  .cnt-sub .mn-cnt ul:not(.tab-lst):not(.tag-lst) > li {
    padding-left: 1em; }
    .cnt-sub .mn-cnt ul:not(.tab-lst):not(.tag-lst) > li::before {
      content: '・'; }
  .cnt-sub .mn-cnt ol:not(.tab-lst):not(.tag-lst) > li {
    padding-left: calc(3.6em / 1.7); }
    .cnt-sub .mn-cnt ol:not(.tab-lst):not(.tag-lst) > li::before {
      content: counter(counter-li) "．";
      width: calc(3.6em / 1.7);
      text-align: right; }
  .cnt-sub .mn-cnt * + ul:not(.tab-lst):not(.tag-lst),
  .cnt-sub .mn-cnt * + ol:not(.tab-lst):not(.tag-lst) {
    margin-top: 0.5em; }
  .cnt-sub .mn-cnt .list_item:nth-child(n+2) {
    margin-top: calc(3em / 1.7);
    padding-top: calc(3em / 1.7);
    border-top: solid 2px rgba(51, 51, 51, 0.2); }
  .cnt-sub .mn-cnt .list_item h2 {
    margin-right: auto;
    font-size: calc(2.3em / 1.7); }
  .cnt-sub .mn-cnt .list_item time {
    font-size: 0.76em;
    display: block; }
  .cnt-sub .mn-cnt .list_item > div:first-child {
    display: flex;
    align-items: center; }
  .cnt-sub .mn-cnt .intrdct {
    border-radius: calc(0.5em / 1.7);
    background-color: #fff; }
    .cnt-sub .mn-cnt .intrdct_head {
      display: flex;
      align-items: center; }
    .cnt-sub .mn-cnt .intrdct_ttl {
      margin-right: auto;
      color: inherit !important;
      font-size: calc(2.3em / 1.7) !important;
      cursor: pointer; }
    .cnt-sub .mn-cnt .intrdct_acrd {
      display: none; }
    .cnt-sub .mn-cnt .intrdct ol {
      margin-top: calc(3.5em / 1.5) !important;
      padding: calc(2em / 1.5);
      font-size: calc(1.5em / 1.7);
      border-radius: calc(0.5em / 1.5);
      background-color: rgba(227, 225, 220, 0.25); }
      .cnt-sub .mn-cnt .intrdct ol > li {
        padding-left: calc(2.2em / 1.7 + 1.3em / 1.7) !important; }
        .cnt-sub .mn-cnt .intrdct ol > li::before {
          content: "(" counter(counter-li) ")" !important;
          width: calc(2.2em / 1.7) !important; }
  .mn-cnt .edit_content h2, .mn-cnt .edit_content h3, .mn-cnt .edit_content h4, .mn-cnt .edit_content h5, .mn-cnt .edit_content h6 {
    margin: 0;
    padding: 0;
    color: #043c78;
    border: 0;
    background-color: transparent;
    position: relative; }
  .tmp11 .mn-cnt .edit_content h2,
  .tmp22 .mn-cnt .edit_content h2,
  .tmp21 .mn-cnt .edit_content h2 {
    font-size: calc(2.9em / 1.7);
    display: flex;
    align-items: center; }
    .tmp11 .mn-cnt .edit_content h2::before, .tmp11 .mn-cnt .edit_content h2::after,
    .tmp22 .mn-cnt .edit_content h2::before,
    .tmp22 .mn-cnt .edit_content h2::after,
    .tmp21 .mn-cnt .edit_content h2::before,
    .tmp21 .mn-cnt .edit_content h2::after {
      background-color: #1c9fca;
      display: block; }
    .tmp11 .mn-cnt .edit_content h2::before,
    .tmp22 .mn-cnt .edit_content h2::before,
    .tmp21 .mn-cnt .edit_content h2::before {
      content: '';
      width: calc(1.2em / 2.9);
      height: calc(1.6em / 2.9);
      margin-right: calc(1.4em / 2.9);
      box-shadow: 0.13793em 0.13793em 0 0 #2c2c79;
      flex-shrink: 0;
      align-self: flex-start; }
    .tmp11 .mn-cnt .edit_content h2::after,
    .tmp22 .mn-cnt .edit_content h2::after,
    .tmp21 .mn-cnt .edit_content h2::after {
      content: '';
      height: calc(1.2em / 2.9);
      margin: 0 calc(1em / 2.9) 0 calc(1.4em / 2.9);
      box-shadow: 0.34483em 0.2069em 0 0 #2c2c79;
      flex-grow: 1;
      position: relative;
      top: calc(-0.3em / 2.9); }
    .tmp11 .mn-cnt .edit_content h2 span,
    .tmp22 .mn-cnt .edit_content h2 span,
    .tmp21 .mn-cnt .edit_content h2 span {
      display: block; }
  .tmp11 .mn-cnt .edit_content * + h2,
  .tmp22 .mn-cnt .edit_content * + h2,
  .tmp21 .mn-cnt .edit_content * + h2 {
    margin-top: 1em; }
  .tmp11 .mn-cnt .edit_content h3,
  .tmp22 .mn-cnt .edit_content h3,
  .tmp21 .mn-cnt .edit_content h3 {
    padding-bottom: calc(1em / 2.3);
    font-size: calc(2.3em / 1.7);
    border-bottom: solid 2px #1c9fca; }
    .tmp11 .mn-cnt .edit_content h3::after,
    .tmp22 .mn-cnt .edit_content h3::after,
    .tmp21 .mn-cnt .edit_content h3::after {
      content: '';
      width: calc(18em / 2.3);
      height: 4px;
      border-radius: 100em;
      background-color: #043c78;
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0; }
  .tmp11 .mn-cnt .edit_content * + h3,
  .tmp22 .mn-cnt .edit_content * + h3,
  .tmp21 .mn-cnt .edit_content * + h3 {
    margin-top: 1em; }
  .tmp11 .mn-cnt .edit_content h4,
  .tmp22 .mn-cnt .edit_content h4,
  .tmp21 .mn-cnt .edit_content h4 {
    padding-left: 0.5em;
    font-size: calc(1.9em / 1.7);
    border-left: solid 5px #1c9fca; }
  .tmp4 .mn-cnt .edit_content h4,
  .tmp8 .mn-cnt .edit_content h4 {
    font-size: calc(2.9em / 1.7);
    display: flex;
    align-items: center; }
    .tmp4 .mn-cnt .edit_content h4::before, .tmp4 .mn-cnt .edit_content h4::after,
    .tmp8 .mn-cnt .edit_content h4::before,
    .tmp8 .mn-cnt .edit_content h4::after {
      background-color: #1c9fca;
      display: block; }
    .tmp4 .mn-cnt .edit_content h4::before,
    .tmp8 .mn-cnt .edit_content h4::before {
      content: '';
      width: calc(1.2em / 2.9);
      height: calc(1.6em / 2.9);
      margin-right: calc(1.4em / 2.9);
      box-shadow: 0.13793em 0.13793em 0 0 #2c2c79;
      flex-shrink: 0;
      align-self: flex-start; }
    .tmp4 .mn-cnt .edit_content h4::after,
    .tmp8 .mn-cnt .edit_content h4::after {
      content: '';
      height: calc(1.2em / 2.9);
      margin: 0 calc(1em / 2.9) 0 calc(1.4em / 2.9);
      box-shadow: 0.34483em 0.2069em 0 0 #2c2c79;
      flex-grow: 1;
      position: relative;
      top: calc(-0.3em / 2.9); }
    .tmp4 .mn-cnt .edit_content h4 span,
    .tmp8 .mn-cnt .edit_content h4 span {
      display: block; }
  .tmp11 .mn-cnt .edit_content * + h4,
  .tmp22 .mn-cnt .edit_content * + h4,
  .tmp21 .mn-cnt .edit_content * + h4 {
    margin-top: 1em; }
  .tmp4 .mn-cnt .edit_content * + h4,
  .tmp8 .mn-cnt .edit_content * + h4 {
    margin-top: calc(5.5em / 2.9); }
  .tmp11 .mn-cnt .edit_content h5,
  .tmp22 .mn-cnt .edit_content h5,
  .tmp21 .mn-cnt .edit_content h5 {
    font-size: 1.11765em;
    padding-bottom: 0.4em;
    border-bottom: solid 2px rgba(102, 102, 102, 0.15); }
  .tmp4 .mn-cnt .edit_content h5,
  .tmp8 .mn-cnt .edit_content h5 {
    padding-bottom: calc(1em / 2.3);
    font-size: calc(2.3em / 1.7);
    border-bottom: solid 2px #1c9fca; }
    .tmp4 .mn-cnt .edit_content h5::after,
    .tmp8 .mn-cnt .edit_content h5::after {
      content: '';
      width: calc(18em / 2.3);
      height: 4px;
      border-radius: 100em;
      background-color: #043c78;
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0; }
  .tmp11 .mn-cnt .edit_content * + h5,
  .tmp22 .mn-cnt .edit_content * + h5,
  .tmp21 .mn-cnt .edit_content * + h5 {
    margin-top: 1em; }
  .tmp4 .mn-cnt .edit_content * + h5,
  .tmp8 .mn-cnt .edit_content * + h5 {
    margin-top: calc(4em / 2.3); }
  .tmp11 .mn-cnt .edit_content h6,
  .tmp22 .mn-cnt .edit_content h6,
  .tmp21 .mn-cnt .edit_content h6 {
    display: flex;
    align-items: baseline; }
    .tmp11 .mn-cnt .edit_content h6::before,
    .tmp22 .mn-cnt .edit_content h6::before,
    .tmp21 .mn-cnt .edit_content h6::before {
      content: '';
      width: 0.47059em;
      height: 3px;
      margin-right: 0.5em;
      background-color: #1c9fca;
      display: block;
      flex-shrink: 0;
      transform: translate(0, -0.29412em); }
  .tmp11 .mn-cnt .edit_content * + h6,
  .tmp22 .mn-cnt .edit_content * + h6,
  .tmp21 .mn-cnt .edit_content * + h6 {
    margin-top: 1em; }
  .mn-cnt .edit_content hr {
    height: 0px;
    border-top: 2px dashed rgba(51, 51, 51, 0.2); }
  .mn-cnt .edit_content * + hr {
    margin: 1.5em 0; }
  .mn-cnt .edit_content p {
    margin: 0;
    font-size: 1em; }
  .mn-cnt .edit_content * + p {
    margin-top: 0.5em; }
  .mn-cnt .edit_content ul {
    padding: 0; }
  .tmp11 .mn-cnt .edit_content ol li,
  .tmp22 .mn-cnt .edit_content ol li,
  .tmp21 .mn-cnt .edit_content ol li {
    padding-left: 2.11765em !important; }
  .mn-cnt .edit_content * + ul,
  .mn-cnt .edit_content * + ol {
    margin-top: 0.5em !important; }
  .mn-cnt .edit_content li {
    margin: 0;
    padding: 0 !important;
    list-style: none; }
    .mn-cnt .edit_content li::before {
      display: none !important; }
    .tmp11 .mn-cnt .edit_content li,
    .tmp22 .mn-cnt .edit_content li,
    .tmp21 .mn-cnt .edit_content li {
      margin-top: 0 !important;
      padding-left: 1em !important; }
      .tmp11 .mn-cnt .edit_content li::before,
      .tmp22 .mn-cnt .edit_content li::before,
      .tmp21 .mn-cnt .edit_content li::before {
        content: '・';
        display: block !important;
        position: relative;
        top: 0;
        left: 0; }
  .mn-cnt .edit_content table {
    margin-top: 0.5em;
    padding: calc(1.5em / 1.7);
    line-height: 1.5;
    background-color: #fff; }
    .mn-cnt .edit_content table, .mn-cnt .edit_content table tbody,
    .mn-cnt .edit_content table tr, .mn-cnt .edit_content table th, .mn-cnt .edit_content table td {
      display: block; }
    .mn-cnt .edit_content table tr, .mn-cnt .edit_content table th, .mn-cnt .edit_content table td {
      display: flex; }
    .mn-cnt .edit_content table th, .mn-cnt .edit_content table td {
      width: auto;
      padding: 0.5em 0.8em 0.5em 0.5em;
      background-color: #eee;
      align-items: center; }
    .mn-cnt .edit_content table tr:nth-child(n+2) {
      margin-top: calc(1em / 1.7); }
    .mn-cnt .edit_content table img {
      height: auto !important;
      max-width: calc(4em / 1.7);
      min-width: 0;
      min-height: 0;
      margin: 0 0.8em 0 0;
      border: 0;
      flex-shrink: 0; }
  .mn-cnt .edit_content .re-tbl {
    padding: 0;
    font-size: 0.88235em;
    border-collapse: separate;
    border-spacing: 5px;
    display: table; }
    .mn-cnt .edit_content .re-tbl tbody {
      display: table-row-group; }
    .mn-cnt .edit_content .re-tbl tr {
      display: table-row; }
      .mn-cnt .edit_content .re-tbl tr:nth-child(n+2) {
        margin-top: auto; }
    .mn-cnt .edit_content .re-tbl th, .mn-cnt .edit_content .re-tbl td {
      width: auto;
      padding: 0.4em 0.6em;
      border-radius: 0.29412em;
      display: table-cell; }
    .mn-cnt .edit_content .re-tbl th {
      background-color: #d8d8d8; }
    .mn-cnt .edit_content .re-tbl img {
      max-width: none;
      margin: 0; }
  .mn-cnt .edit_content .tbl-scrl {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none; }
    .mn-cnt .edit_content .tbl-scrl::-webkit-scrollbar {
      display: none; }
    .mn-cnt .edit_content .tbl-scrl > table {
      min-width: 730px; }
  .tmp17 .mn-cnt .list_box {
    margin-top: calc(4.5em / 1.7); }
  .tmp17 .mn-cnt .list_item .ttl-box {
    margin-right: auto; }
  .tmp17 .mn-cnt .list_item .dvl-dtl {
    margin-top: calc(0.8em / 1.7);
    font-weight: 700;
    line-height: 1.5; }
    .tmp17 .mn-cnt .list_item .dvl-dtl > div {
      display: flex;
      align-items: flex-start; }
    .tmp17 .mn-cnt .list_item .dvl-dtl dt {
      display: flex;
      align-items: center; }
      .tmp17 .mn-cnt .list_item .dvl-dtl dt::after {
        content: '';
        width: 2px;
        height: calc(1.4em / 1.7);
        margin-left: calc(1.2em / 1.7);
        background-color: #666;
        display: block; }
    .tmp17 .mn-cnt .list_item .dvl-dtl dd {
      margin-left: calc(1.2em / 1.7);
      color: #666; }
      .tmp17 .mn-cnt .list_item .dvl-dtl dd > span {
        display: inline-block; }
        .tmp17 .mn-cnt .list_item .dvl-dtl dd > span:nth-last-child(n+2)::after {
          content: '、'; }
  .tmp17 .mn-cnt .list_item ._lead {
    margin-top: calc(1.8em / 1.5);
    color: #666;
    font-size: calc(1.5em / 1.7); }
    .tmp17 .mn-cnt .list_item ._lead + .splt {
      display: none; }
  @media screen and (max-width: 740px) {
    .cnt-sub .mn-cnt .intrdct {
      padding: 0 20px; }
      .cnt-sub .mn-cnt .intrdct_head {
        padding: 20px 0; }
      .cnt-sub .mn-cnt .intrdct_acrd {
        padding-bottom: 20px; }
    .mn-cnt .edit_content table th:nth-child(n+2), .mn-cnt .edit_content table td:nth-child(n+2) {
      margin-top: calc(1em / 1.7); }
    .mn-cnt .edit_content table tr {
      display: block; } }
  @media screen and (max-width: 425px) {
    .cnt-sub .mn-cnt .intrdct_head {
      display: block; } }
  @media screen and (max-width: 480px) {
    .cnt-sub .mn-cnt .list_item > div:first-child {
      display: block; } }
  @media screen and (min-width: 741px) {
    .cnt-sub .mn-cnt .intrdct {
      padding: 0 40px; }
      .cnt-sub .mn-cnt .intrdct_head {
        padding: 40px 0; }
      .cnt-sub .mn-cnt .intrdct_acrd {
        padding-bottom: 40px; }
    .mn-cnt .edit_content table th, .mn-cnt .edit_content table td {
      width: calc((100% - 1.5em / 1.7 * 2) / 3); }
      .mn-cnt .edit_content table th:nth-child(n+2), .mn-cnt .edit_content table td:nth-child(n+2) {
        margin-left: calc(1.5em / 1.7); }
    .tmp12 .mn-cnt .list_box {
      display: flex;
      flex-wrap: wrap; }
    .tmp12 .mn-cnt .list_item {
      width: calc((100% - 40px) / 2); }
      .tmp12 .mn-cnt .list_item:nth-child(2) {
        margin-top: 0;
        padding-top: 0;
        border-top: 0; }
      .tmp12 .mn-cnt .list_item:nth-child(even) {
        margin-left: 40px; } }

.mn-art {
  padding: calc(6em / 1.7) 0 calc(8em / 1.7); }
  .cnt-sub .mn-art {
    padding-top: 0; }
  .tmp9 .mn-art, .tmp10 .mn-art,
  .tmp11 .mn-art, .tmp22 .mn-art,
  .pg100 .mn-art, .pg1827 .mn-art {
    padding-bottom: 0; }
  .cnt-sub .mn-art_inr {
    padding-top: calc(3.5em); }
  .pg100 .mn-art_inr,
  .pg1827 .mn-art_inr {
    display: none; }
  @media screen and (min-width: 741px) {
    .cnt-top .mn-art_inr {
      display: flex; } }

.hr-head {
  color: #fff;
  background-color: #043c78;
  background-image: url(../img/img_bg_hrh.jpg);
  background-position: center;
  background-size: cover;
  padding: calc(3.5em / 1.7) 0;
  position: relative; }
  .hr-head::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    background-image: url(../img/img_bg_dot.svg);
    background-position: left top;
    background-size: 4px 4px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; }
  .hr-head_ttl {
    font-size: calc(3.5em / 1.7);
    display: flex;
    flex-direction: column; }
  .hr-head_ttl-prm {
    margin-top: calc(1.2em / 3.5); }
  .hr-head_ttl-scnd {
    font-size: calc(2.1em / 3.5); }
    .hr-head_ttl-scnd.-en {
      font-size: calc(1.7em / 3.5); }
  .hr-head_ttl-dt {
    font-size: calc(1.7em / 3.5); }
  .hr-head_dpy {
    display: inline-flex;
    align-items: center; }
    .hr-head_dpy::after {
      content: '';
      width: 2px;
      height: 0.7em;
      margin: 0 0.5em;
      background-color: currentColor;
      display: block; }
  .hr-head_nts {
    margin-top: calc(2.6em / 1.5) !important;
    font-size: calc(1.5em / 1.7);
    font-weight: 700; }
  .hr-head > * {
    position: relative;
    z-index: 5; }
  .tmp9 .hr-head_ttl-prm,
  .tmp10 .hr-head_ttl-prm {
    margin-top: calc(0.5em / 3.5); }
  .tmp9 .hr-head_ttl-scnd.-dvlp,
  .tmp10 .hr-head_ttl-scnd.-dvlp {
    margin-top: calc(1.2em / 1.9); }
  .tmp11 .hr-head_ttl-prm,
  .tmp22 .hr-head_ttl-prm {
    margin-top: 0; }
  @media screen and (min-width: 741px) {
    .hr-head_flx {
      display: flex;
      justify-content: space-between; } }
  @media screen and (min-width: 741px) and (max-width: 880px) {
    .hr-head_flx {
      display: block; } }

.author {
  width: auto;
  font-size: 0.88235em;
  line-height: 1.5;
  flex-shrink: 0; }
  .author tr {
    vertical-align: top; }
    .author tr:nth-child(n+2) th, .author tr:nth-child(n+2) td {
      padding-top: 0.5em; }
  .author ._name {
    font-weight: 700; }
  .author ._group {
    max-width: calc(12em + 2em);
    padding-left: 2em; }
  @media screen and (max-width: 740px) {
    .author {
      width: 100%;
      margin-top: 1.84615em; }
      .author ._name {
        width: 7em; }
      .author ._group {
        width: 80%; } }
  @media screen and (min-width: 741px) {
    .author {
      margin-left: 40px; } }
  @media screen and (min-width: 741px) and (max-width: 880px) {
    .author {
      width: 100%;
      margin: 1.73333em 0 0; }
      .author ._name {
        width: 7em; }
      .author ._group {
        width: 80%; } }

.his-sct + * {
  margin-top: 2.4em; }

.his-sct h2, .his-sct h3, .his-sct h4, .his-sct h5, .his-sct h6 {
  margin: 0;
  padding: 0;
  color: #043c78;
  border: 0;
  background-color: transparent;
  position: relative; }

.his-sct h2 {
  font-size: calc(2.9em / 1.7);
  display: flex;
  align-items: center; }
  .his-sct h2::before, .his-sct h2::after {
    background-color: #1c9fca;
    display: block; }
  .his-sct h2::before {
    content: '';
    width: calc(1.2em / 2.9);
    height: calc(1.6em / 2.9);
    margin-right: calc(1.4em / 2.9);
    box-shadow: 0.13793em 0.13793em 0 0 #2c2c79;
    flex-shrink: 0;
    align-self: flex-start; }
  .his-sct h2::after {
    content: '';
    height: calc(1.2em / 2.9);
    margin: 0 calc(1em / 2.9) 0 calc(1.4em / 2.9);
    box-shadow: 0.34483em 0.2069em 0 0 #2c2c79;
    flex-grow: 1;
    position: relative;
    top: calc(-0.3em / 2.9); }
  .his-sct h2 span {
    display: block; }

.his-sct * + h2 {
  margin-top: 1em; }

.his-sct h3 {
  padding-bottom: calc(1em / 2.3);
  font-size: calc(2.3em / 1.7);
  border-bottom: solid 2px #1c9fca; }
  .his-sct h3::after {
    content: '';
    width: calc(18em / 2.3);
    height: 4px;
    border-radius: 100em;
    background-color: #043c78;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0; }

.his-sct * + h3 {
  margin-top: 1em; }

.his-sct h4 {
  padding-left: 0.5em;
  font-size: calc(1.9em / 1.7);
  border-left: solid 5px #1c9fca; }

.his-sct * + h4 {
  margin-top: 1em; }

.his-sct h5 {
  font-size: 1.11765em;
  padding-bottom: 0.4em;
  border-bottom: solid 2px rgba(102, 102, 102, 0.15); }

.his-sct * + h5 {
  margin-top: 1em; }

.his-sct h6 {
  display: flex;
  align-items: baseline; }
  .his-sct h6::before {
    content: '';
    width: 0.47059em;
    height: 3px;
    margin-right: 0.5em;
    background-color: #1c9fca;
    display: block;
    flex-shrink: 0;
    transform: translate(0, -0.29412em); }

.his-sct * + h6 {
  margin-top: 1em; }

.his-sct hr {
  height: 0px;
  border-top: 2px dashed rgba(51, 51, 51, 0.2); }

.his-sct * + hr {
  margin: 1em 0; }

.his-sct ul > li, .his-sct ol > li {
  padding: 1em;
  background-color: #fff; }
  .his-sct ul > li::before, .his-sct ol > li::before {
    top: 1em !important;
    left: 1em !important; }

.his-sct ul > li {
  padding-left: 2em !important; }

.his-sct ol > li {
  padding-left: calc(3.6em / 1.7 + 1em) !important; }
  .his-sct ol > li::before {
    width: calc(3.6em / 1.7 + 1em); }

.ppr-sct {
  padding: 1.8em;
  border-radius: 5px;
  background-color: #fff; }
  .ppr-sct + * {
    margin-top: 2.4em; }
  .ppr-sct hr:not(.splt) {
    height: 0px;
    border-top: 2px dashed rgba(51, 51, 51, 0.2); }
  .ppr-sct * + hr {
    margin: 1em 0; }

.figure_box {
  max-width: 680px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center; }
  .figure_box.-mov {
    margin-top: 1.47059em; }
  * + .figure_box {
    margin-top: 2.35em; }
  .his-sct .figure_box {
    margin-top: 1em; }
  .figure_box figure {
    padding: 1.2em;
    border-radius: 5px;
    background-color: rgba(227, 225, 220, 0.6); }
  .figure_box figcaption {
    margin-top: 1.2em;
    color: #716e65;
    font-size: 0.88235em;
    font-weight: 700; }
  .figure_box img {
    width: 100%; }

.tab {
  margin-top: calc(2em / 1.7);
  padding: calc(0.8em / 1.7) 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
  z-index: 5; }
  .tab::-webkit-scrollbar {
    display: none; }

.tab-lst {
  font-weight: 700;
  line-height: 1.4;
  display: flex; }
  .tab-lst::before, .tab-lst::after {
    content: '';
    display: block;
    flex-shrink: 0; }
  .tab-lst_itm {
    overflow: hidden;
    border: solid 1px #fff;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.15);
    flex-shrink: 0;
    transition: color 400ms cubic-bezier(0.23, 1, 0.32, 1), background-color 400ms cubic-bezier(0.23, 1, 0.32, 1); }
    .tab-lst_itm:nth-child(n+2) {
      margin-left: calc(0.5em / 1.7); }
    .tab-lst_itm.is-actv {
      color: #043c78;
      background-color: white; }
  .tab-lst_lnk {
    padding: 0.5em 1em;
    display: block; }
  @media screen and (max-width: 740px) {
    .tab-lst::before, .tab-lst::after {
      width: 20px; } }
  @media screen and (max-width: 425px) {
    .tab-lst {
      font-size: calc(1.3em / 1.5); }
      .tab-lst_lnk {
        padding: calc(1em / 1.3) calc(1.8em / 1.3);
        display: block; } }
  @media screen and (min-width: 741px) {
    .tab-lst::before, .tab-lst::after {
      width: 60px; }
    .tab-lst_itm:hover {
      color: #043c78;
      background-color: white; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .tab-lst::before, .tab-lst::after {
      width: 40px; } }

._cate {
  margin-bottom: 0.33333em;
  padding-left: 0.53333em;
  color: #fff;
  font-size: 0.6em;
  font-weight: 700;
  line-height: 1;
  border-left: solid 0.2em currentColor;
  display: block; }
  ._cate._news {
    color: #2d88ea; }
    ._cate._news::before {
      content: 'ROBOGAKU'; }
  ._cate._rij {
    color: #1c9fca; }
    ._cate._rij::before {
      content: 'LABORATORY'; }
  ._cate._history {
    color: #13d0ff; }
    ._cate._history::before {
      content: 'HISTORY'; }

.no-rslt + .prev_next {
  display: none; }

.prev_next {
  margin-bottom: 3em;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
  .prev_next span a {
    color: #fff;
    background-color: #043c78; }
  .prev_next > span {
    margin-bottom: 5px; }
    .prev_next > span:nth-child(n+2) {
      margin-left: 5px; }
    .prev_next > span > * {
      min-width: 3em;
      height: 2.7em;
      padding: 0.5em !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      background-color: #ccc; }
  @media screen and (min-width: 741px) {
    .prev_next span a {
      transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
      .prev_next span a:hover {
        opacity: 0.4; } }

.cnt-sub .mn-cnt section + .prev_next {
  margin-top: 3em !important; }

.nts {
  margin-bottom: 1.76471em !important;
  padding: 0.8em 1em;
  border: solid 2px #ca1c51;
  border-radius: 3px;
  background-color: #fff; }
  * + .nts {
    margin: 0.5em 0 !important; }

.fbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .fbox .figure_box {
    width: calc(50% - 20px);
    background-color: rgba(227, 225, 220, 0.6); }
  .fbox figure {
    background-color: transparent; }

.splt {
  width: calc(4em / 1.7);
  margin: calc(2.5em / 1.7) auto;
  height: 3px;
  background-color: #043c78;
  background-image: linear-gradient(90deg, #969da5 0%, #969da5 50%, #043c78 50%, #043c78 100%); }
  .news .splt,
  .hr-head .splt {
    display: none; }
  .ppr-sct .splt {
    margin: 0.8em 0 0; }
    .ua-ie .ppr-sct .splt {
      margin-right: auto; }
  .list_item .splt {
    margin: calc(1.5em / 1.7) 0 0; }
    .ua-ie .list_item .splt {
      margin-right: auto; }
  .ajaxSearch_result .splt {
    margin: 0.47059em 0 0.58824em; }
    .ua-ie .ajaxSearch_result .splt {
      margin-right: auto; }

#mainContainer {
  min-width: 0 !important; }

.pdf-js-unt {
  width: 100%;
  aspect-ratio: 1 / 1.414;
  border: 0; }

/* Top page styles
   ======================================== */
.news {
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #043c78;
  background-image: linear-gradient(110deg, #0686a1 0%, #056289 25%, #0b497a 50%, #103870 75%, #12316b 100%); }
  .cnt-sub .news.-his {
    padding-top: 2.05882em;
    padding-bottom: 2.05882em;
    margin-top: 4.70588em; }
  .news_ttl {
    margin-bottom: 0.8em;
    color: #fff !important;
    font-size: 1.47059em !important;
    text-align: center; }
  .news_inr {
    display: flex;
    flex-wrap: wrap; }
  @media screen and (max-width: 425px) {
    .news {
      display: block; } }

.news-art {
  color: #fff; }
  .news-art_lnk {
    display: block;
    position: relative;
    z-index: 0; }
  .news-art_thmb {
    width: calc(100% - 1.5em / 1.7);
    height: calc(14em / 1.7);
    padding: 0 1em;
    color: #142f85;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .news-art_thmb::before, .news-art_thmb::after {
      content: '';
      display: block;
      position: absolute; }
    .news-art_thmb::before {
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0;
      top: 0;
      left: 0;
      z-index: 10;
      transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
    .news-art_thmb::after {
      content: '';
      width: calc(100% - 2.5em / 1.7);
      height: calc(100% - 1em / 1.7);
      background-color: #1c9fca;
      background-image: linear-gradient(135deg, #12316b 0%, #103870 25%, #0b497a 50%, #056289 75%, #0686a1 100%);
      top: calc(2em / 1.7);
      right: calc(-1.5em / 1.7);
      z-index: -1;
      transform: translate(0, 0);
      transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1); }
  .news-art_thmb-img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5; }
    .news-art_thmb-img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;'; }
  .news-art_ttl {
    margin-top: calc(2.5em / 1.5);
    font-size: 0.88235em;
    display: flex;
    flex-direction: column;
    transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
    .cnt-sub .-his .news-art_ttl {
      font-size: 0.88235em;
      color: #fff; }
  .news-art_dt {
    margin-bottom: calc(0.5em / 1.3);
    font-size: calc(1.3em / 1.5); }
    .-his .news-art_dt {
      display: none; }
  @media screen and (max-width: 740px) {
    .news-art {
      width: calc((100% - 20px) / 2); }
      .news-art:nth-child(n+3) {
        margin-top: calc(3.5em / 1.5); }
      .news-art:nth-child(even) {
        margin-left: 20px; } }
  @media screen and (max-width: 425px) {
    .news-art {
      width: 100%; }
      .news-art:nth-child(n+1) {
        margin: 0; }
      .news-art:nth-child(n+2) {
        margin-top: calc(4.5em / 1.5); } }
  @media screen and (min-width: 741px) {
    .news-art {
      width: calc((100% - 40px * 2) / 3); }
      .news-art:nth-child(n+4) {
        margin-top: 40px; }
      .news-art:nth-child(n+2) {
        margin-left: 40px; }
      .news-art:nth-child(3n+1) {
        margin-left: 0; }
      .ua-ie .news-art {
        width: calc(33.33% - 40px); }
      .news-art_lnk:hover .news-art_thmb::before {
        opacity: 0.65; }
      .news-art_lnk:hover .news-art_thmb::after {
        transform: translate(-0.88235em, -0.58824em); }
      .news-art_lnk:hover .news-art_ttl {
        opacity: 0.4; } }
  @media screen and (min-width: 741px) and (max-width: 880px) {
    .news-art,
    .ua-ie .news-art {
      width: calc((100% - 40px) / 2); }
    .news-art:nth-child(n+1) {
      margin: 0; }
    .news-art:nth-child(n+3) {
      margin-top: 40px; }
    .news-art:nth-child(even) {
      margin-left: 40px; } }

.top-pg-sct_ttl {
  color: #043c78;
  font-size: calc(2.5em / 1.7);
  text-align: center;
  display: flex;
  flex-direction: column; }

.top-pg-sct_ttl-scnd {
  margin-top: calc(0.5em / 1.5);
  color: #1c9fca;
  font-size: calc(1.5em / 2.5); }

@media screen and (max-width: 740px) {
  .top-pg-sct:nth-child(n+2) {
    margin-top: calc(6em / 1.7); } }

@media screen and (min-width: 741px) {
  .top-pg-sct {
    width: calc((100% - 60px) / 2);
    display: flex;
    flex-direction: column;
    align-items: center; }
    .top-pg-sct:nth-child(n+2) {
      margin-left: 60px; }
    .top-pg-sct_intr {
      margin-bottom: auto; }
      .ua-ie .top-pg-sct_intr {
        width: 100%; } }

@media screen and (min-width: 741px) and (max-width: 1050px) {
  .top-pg-sct {
    width: calc((100% - 40px) / 2); }
    .top-pg-sct:nth-child(n+2) {
      margin-left: 40px; } }

/* ==============================================================================================
   Utility classes
   ============================================================================================== */
/*
 * text classes
 */
.utl-fwn {
  font-weight: 400; }

.utl-fwb {
  font-weight: 600; }

.utl-fwnsnsjp-thin {
  font-weight: 100; }

.utl-fwnsnsjp-light {
  font-weight: 300; }

.utl-fwnsnsjp-regular {
  font-weight: 400; }

.utl-fwnsnsjp-medium {
  font-weight: 500; }

.utl-fwnsnsjp-bold {
  font-weight: 700; }

.utl-fwnsnsjp-black {
  font-weight: 900; }

.utl-tal {
  text-align: left; }

.utl-tar {
  text-align: right; }

.utl-tac {
  text-align: center; }

.utl-taj {
  text-align: justify; }

/*
 * content display classes
 */
.utl-di {
  display: inline; }

.utl-db {
  display: block; }

.utl-dib {
  display: inline-block; }

.utl-dflx {
  display: flex; }

.utl-diflx {
  display: inline-flex; }

/*
 * position classes
 */
.utl-rltv {
  position: relative; }

.utl-absl {
  position: absolute; }

.utl-fxd {
  position: fixed; }

/*
 * link disable
 */
.utl-nolnk {
  pointer-events: none; }

/*
 * anchor block
 */
.utl-anch-blk a, a.utl-anch-blk {
  display: block; }

/*
 * text break
 */
.utl-br {
  display: inline-block; }

/*
 * text indent
 */
.utl-indent {
  text-indent: 1em; }

.utl-neg-indent {
  margin-left: 1em;
  text-indent: -1em; }

/*
 * image replacement
 */
.utl-ir {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

/*
 * mouse over action
 */
@media screen and (min-width: 741px) {
  .utl-ovr {
    transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
    .utl-ovr:hover {
      opacity: 0.4; } }

/*
 * add pointer effect
 */
.utl-ptr {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/*
 * add inertial scroll to mobile device
 */
.utl-i-scroll {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch; }

/*
 * scroll bar hidden
 */
.utl-scroll-bar-hdn {
  scrollbar-width: none;
  -ms-overflow-style: none; }
  .utl-scroll-bar-hdn::-webkit-scrollbar {
    display: none; }

/*
 * change svg color
 */
.utl-chg-svg-col {
  fill: currentColor; }

/*
 * clear fix
 */
.utl-cf::before, .utl-cf::after {
  content: '';
  display: table; }

.utl-cf::after {
  clear: both; }

/*
 * hide visually and from screen readers, but maintain layout
 */
.utl-invisible {
  visibility: hidden; }

/*
 * hide visually and from screen readers
 */
.utl-hdn {
  display: none !important; }

@media screen and (max-width: 740px) {
  .utl-mbl-hdn {
    display: none; } }

@media screen and (min-width: 741px) {
  .utl-pc-hdn {
    display: none; } }

@media screen and (min-width: 741px) and (max-width: 1050px) {
  .utl-tblt-hdn {
    display: none; } }

/*
 * hide only visually, but have it available for screen readers
 */
.utl-visually-hdn, .utl-visually-hdn-foc {
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0); }

/*
 * extends the .utl-visually-hdn class to allow the element
 * to be focusable when navigated to via the keyboard
 */
.utl-visually-hdn-foc:active, .utl-visually-hdn-foc:focus {
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  clip: auto; }

.w5 {
  width: 5% !important; }

.w10 {
  width: 10% !important; }

.w15 {
  width: 15% !important; }

.w20 {
  width: 20% !important; }

.w25 {
  width: 25% !important; }

.w30 {
  width: 30% !important; }

.w35 {
  width: 35% !important; }

.w40 {
  width: 40% !important; }

.w45 {
  width: 45% !important; }

.w50 {
  width: 50% !important; }

.w55 {
  width: 55% !important; }

.w60 {
  width: 60% !important; }

.w65 {
  width: 65% !important; }

.w70 {
  width: 70% !important; }

.w75 {
  width: 75% !important; }

.w80 {
  width: 80% !important; }

.w85 {
  width: 85% !important; }

.w90 {
  width: 90% !important; }

.w95 {
  width: 95% !important; }

.w100 {
  width: 100% !important; }

.mceContentBody {
  padding: 1.5em 2em; }
  .mceContentBody.cnt-sub h2, .mceContentBody.cnt-sub h3 {
    color: #043c78; }
  .mceContentBody.cnt-sub h2 {
    font-size: 1.70588em; }
  .mceContentBody.cnt-sub h3 {
    font-size: 1.35294em; }
  .mceContentBody.cnt-sub * + p {
    margin-top: 0.5em; }
  .mceContentBody.cnt-sub ul, .mceContentBody.cnt-sub ol {
    counter-reset: counter-li; }
    .mceContentBody.cnt-sub ul:not(.tab-lst):not(.tag-lst) > li, .mceContentBody.cnt-sub ol:not(.tab-lst):not(.tag-lst) > li {
      position: relative;
      counter-increment: counter-li; }
      .mceContentBody.cnt-sub ul:not(.tab-lst):not(.tag-lst) > li:nth-child(n+2), .mceContentBody.cnt-sub ol:not(.tab-lst):not(.tag-lst) > li:nth-child(n+2) {
        margin-top: 0.5em; }
      .mceContentBody.cnt-sub ul:not(.tab-lst):not(.tag-lst) > li::before, .mceContentBody.cnt-sub ol:not(.tab-lst):not(.tag-lst) > li::before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0; }
  .mceContentBody.cnt-sub ul:not(.tab-lst):not(.tag-lst) > li {
    padding-left: 1em; }
    .mceContentBody.cnt-sub ul:not(.tab-lst):not(.tag-lst) > li::before {
      content: '・'; }
  .mceContentBody.cnt-sub ol:not(.tab-lst):not(.tag-lst) > li {
    padding-left: calc(4.5em / 1.7); }
    .mceContentBody.cnt-sub ol:not(.tab-lst):not(.tag-lst) > li::before {
      content: counter(counter-li) "．";
      width: calc(4.5em / 1.7);
      text-align: right; }
  .mceContentBody.cnt-sub * + ul:not(.tab-lst):not(.tag-lst),
  .mceContentBody.cnt-sub * + ol:not(.tab-lst):not(.tag-lst) {
    margin-top: 0.5em; }
  .mceContentBody.cnt-sub .list_item:nth-child(n+2) {
    margin-top: calc(3em / 1.7);
    padding-top: calc(3em / 1.7);
    border-top: solid 2px rgba(51, 51, 51, 0.2); }
  .mceContentBody.cnt-sub .list_item h2 {
    margin-right: auto;
    font-size: calc(2.3em / 1.7); }
  .mceContentBody.cnt-sub .list_item time {
    font-size: 0.76em;
    display: block; }
  .mceContentBody.cnt-sub .list_item > div:first-child {
    display: flex;
    align-items: center; }
  .mceContentBody.cnt-sub .intrdct {
    border-radius: calc(0.5em / 1.7);
    background-color: #fff; }
    .mceContentBody.cnt-sub .intrdct_head {
      display: flex;
      align-items: center; }
    .mceContentBody.cnt-sub .intrdct_ttl {
      margin-right: auto;
      color: inherit !important;
      font-size: calc(2.3em / 1.7) !important;
      cursor: pointer; }
    .mceContentBody.cnt-sub .intrdct_acrd {
      display: none; }
    .mceContentBody.cnt-sub .intrdct ol {
      margin-top: calc(3.5em / 1.5) !important;
      padding: calc(2em / 1.5);
      font-size: calc(1.5em / 1.7);
      border-radius: calc(0.5em / 1.5);
      background-color: rgba(227, 225, 220, 0.25); }
      .mceContentBody.cnt-sub .intrdct ol > li {
        padding-left: calc(2.2em / 1.7 + 1.3em / 1.7) !important; }
        .mceContentBody.cnt-sub .intrdct ol > li::before {
          content: "(" counter(counter-li) ")" !important;
          width: calc(2.2em / 1.7) !important; }
  .mceContentBody.edit_content h2, .mceContentBody.edit_content h3, .mceContentBody.edit_content h4, .mceContentBody.edit_content h5, .mceContentBody.edit_content h6 {
    margin: 0;
    padding: 0;
    color: #043c78;
    border: 0;
    background-color: transparent;
    position: relative; }
  .tmp11.mceContentBody.edit_content h2,
  .tmp21.mceContentBody.edit_content h2 {
    font-size: calc(2.9em / 1.7);
    display: flex;
    align-items: center; }
    .tmp11.mceContentBody.edit_content h2::before, .tmp11.mceContentBody.edit_content h2::after,
    .tmp21.mceContentBody.edit_content h2::before,
    .tmp21.mceContentBody.edit_content h2::after {
      background-color: #1c9fca;
      display: block; }
    .tmp11.mceContentBody.edit_content h2::before,
    .tmp21.mceContentBody.edit_content h2::before {
      content: '';
      width: calc(1.2em / 2.9);
      height: calc(1.6em / 2.9);
      margin-right: calc(1.4em / 2.9);
      box-shadow: 0.13793em 0.13793em 0 0 #2c2c79;
      flex-shrink: 0;
      align-self: flex-start; }
    .tmp11.mceContentBody.edit_content h2::after,
    .tmp21.mceContentBody.edit_content h2::after {
      content: '';
      height: calc(1.2em / 2.9);
      margin: 0 calc(1em / 2.9) 0 calc(1.4em / 2.9);
      box-shadow: 0.34483em 0.2069em 0 0 #2c2c79;
      flex-grow: 1;
      position: relative;
      top: calc(-0.3em / 2.9); }
    .tmp11.mceContentBody.edit_content h2 span,
    .tmp21.mceContentBody.edit_content h2 span {
      display: block; }
  .tmp11.mceContentBody.edit_content * + h2,
  .tmp21.mceContentBody.edit_content * + h2 {
    margin-top: 1em; }
  .tmp11.mceContentBody.edit_content h3,
  .tmp21.mceContentBody.edit_content h3 {
    padding-bottom: calc(1em / 2.3);
    font-size: calc(2.3em / 1.7);
    border-bottom: solid 2px #1c9fca; }
    .tmp11.mceContentBody.edit_content h3::after,
    .tmp21.mceContentBody.edit_content h3::after {
      content: '';
      width: calc(18em / 2.3);
      height: 4px;
      border-radius: 100em;
      background-color: #043c78;
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0; }
  .tmp11.mceContentBody.edit_content * + h3,
  .tmp21.mceContentBody.edit_content * + h3 {
    margin-top: 1em; }
  .tmp11.mceContentBody.edit_content h4,
  .tmp21.mceContentBody.edit_content h4 {
    padding-left: 0.5em;
    font-size: calc(1.9em / 1.7);
    border-left: solid 5px #1c9fca; }
  .tmp4.mceContentBody.edit_content h4,
  .tmp8.mceContentBody.edit_content h4 {
    font-size: calc(2.9em / 1.7);
    display: flex;
    align-items: center; }
    .tmp4.mceContentBody.edit_content h4::before, .tmp4.mceContentBody.edit_content h4::after,
    .tmp8.mceContentBody.edit_content h4::before,
    .tmp8.mceContentBody.edit_content h4::after {
      background-color: #1c9fca;
      display: block; }
    .tmp4.mceContentBody.edit_content h4::before,
    .tmp8.mceContentBody.edit_content h4::before {
      content: '';
      width: calc(1.2em / 2.9);
      height: calc(1.6em / 2.9);
      margin-right: calc(1.4em / 2.9);
      box-shadow: 0.13793em 0.13793em 0 0 #2c2c79;
      flex-shrink: 0;
      align-self: flex-start; }
    .tmp4.mceContentBody.edit_content h4::after,
    .tmp8.mceContentBody.edit_content h4::after {
      content: '';
      height: calc(1.2em / 2.9);
      margin: 0 calc(1em / 2.9) 0 calc(1.4em / 2.9);
      box-shadow: 0.34483em 0.2069em 0 0 #2c2c79;
      flex-grow: 1;
      position: relative;
      top: calc(-0.3em / 2.9); }
    .tmp4.mceContentBody.edit_content h4 span,
    .tmp8.mceContentBody.edit_content h4 span {
      display: block; }
  .tmp11.mceContentBody.edit_content * + h4,
  .tmp21.mceContentBody.edit_content * + h4 {
    margin-top: 1em; }
  .tmp4.mceContentBody.edit_content * + h4,
  .tmp8.mceContentBody.edit_content * + h4 {
    margin-top: calc(5.5em / 2.9); }
  .tmp11.mceContentBody.edit_content h5,
  .tmp21.mceContentBody.edit_content h5 {
    font-size: 1.11765em;
    padding-bottom: 0.4em;
    border-bottom: solid 2px rgba(102, 102, 102, 0.15); }
  .tmp4.mceContentBody.edit_content h5,
  .tmp8.mceContentBody.edit_content h5 {
    padding-bottom: calc(1em / 2.3);
    font-size: calc(2.3em / 1.7);
    border-bottom: solid 2px #1c9fca; }
    .tmp4.mceContentBody.edit_content h5::after,
    .tmp8.mceContentBody.edit_content h5::after {
      content: '';
      width: calc(18em / 2.3);
      height: 4px;
      border-radius: 100em;
      background-color: #043c78;
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0; }
  .tmp11.mceContentBody.edit_content * + h5,
  .tmp21.mceContentBody.edit_content * + h5 {
    margin-top: 1em; }
  .tmp4.mceContentBody.edit_content * + h5,
  .tmp8.mceContentBody.edit_content * + h5 {
    margin-top: calc(4em / 2.3); }
  .tmp11.mceContentBody.edit_content h6,
  .tmp21.mceContentBody.edit_content h6 {
    display: flex;
    align-items: baseline; }
    .tmp11.mceContentBody.edit_content h6::before,
    .tmp21.mceContentBody.edit_content h6::before {
      content: '';
      width: 0.47059em;
      height: 3px;
      margin-right: 0.5em;
      background-color: #1c9fca;
      display: block;
      flex-shrink: 0;
      transform: translate(0, -0.29412em); }
  .tmp11.mceContentBody.edit_content * + h6,
  .tmp21.mceContentBody.edit_content * + h6 {
    margin-top: 1em; }
  .mceContentBody.edit_content hr {
    height: 0px;
    border-top: 2px dashed rgba(51, 51, 51, 0.2); }
  .mceContentBody.edit_content * + hr {
    margin: 1em 0; }
  .mceContentBody.edit_content p {
    margin: 0;
    font-size: 1em; }
  .mceContentBody.edit_content * + p {
    margin-top: 0.5em; }
  .mceContentBody.edit_content ul {
    padding: 0; }
  .tmp11.mceContentBody.edit_content ol li,
  .tmp21.mceContentBody.edit_content ol li {
    padding-left: 2.11765em !important; }
  .mceContentBody.edit_content * + ul,
  .mceContentBody.edit_content * + ol {
    margin-top: 0.5em !important; }
  .mceContentBody.edit_content li {
    margin: 0;
    padding: 0 !important;
    list-style: none; }
    .mceContentBody.edit_content li::before {
      display: none !important; }
    .tmp11.mceContentBody.edit_content li,
    .tmp21.mceContentBody.edit_content li {
      margin-top: 0 !important;
      padding-left: 1em !important; }
      .tmp11.mceContentBody.edit_content li::before,
      .tmp21.mceContentBody.edit_content li::before {
        content: '・';
        display: block !important;
        position: relative;
        top: 0;
        left: 0; }
  .tmp4.mceContentBody.edit_content li a,
  .tmp8.mceContentBody.edit_content li a {
    display: inline-flex;
    align-items: baseline; }
    .tmp4.mceContentBody.edit_content li a::before,
    .tmp8.mceContentBody.edit_content li a::before {
      content: '\e90e';
      margin-right: calc(1em / 1.9);
      font-family: icomoon;
      font-size: calc(1.9em / 1.7);
      font-weight: 500;
      line-height: 1;
      display: block;
      position: relative;
      top: calc(0.2em / 1.9); }
  .mceContentBody.edit_content table {
    margin-top: 0.5em;
    padding: calc(1.5em / 1.7);
    line-height: 1.5;
    background-color: #fff; }
    .mceContentBody.edit_content table, .mceContentBody.edit_content table tbody,
    .mceContentBody.edit_content table tr, .mceContentBody.edit_content table th, .mceContentBody.edit_content table td {
      display: block; }
    .mceContentBody.edit_content table tr, .mceContentBody.edit_content table th, .mceContentBody.edit_content table td {
      display: flex; }
    .mceContentBody.edit_content table th, .mceContentBody.edit_content table td {
      width: auto;
      padding: 0.5em 0.8em 0.5em 0.5em;
      background-color: #eee;
      align-items: center; }
    .mceContentBody.edit_content table tr:nth-child(n+2) {
      margin-top: calc(1em / 1.7); }
    .mceContentBody.edit_content table img {
      height: auto !important;
      max-width: calc(4em / 1.7);
      min-width: 0;
      min-height: 0;
      margin: 0 0.8em 0 0;
      border: 0;
      flex-shrink: 0; }
  .tmp17.mceContentBody .list_box {
    margin-top: calc(4.5em / 1.7); }
  .tmp17.mceContentBody .list_item .ttl-box {
    margin-right: auto; }
  .tmp17.mceContentBody .list_item .dvl-dtl {
    margin-top: calc(0.8em / 1.7);
    font-weight: 700;
    line-height: 1.5; }
    .tmp17.mceContentBody .list_item .dvl-dtl > div {
      display: flex;
      align-items: flex-start; }
    .tmp17.mceContentBody .list_item .dvl-dtl dt {
      display: flex;
      align-items: center; }
      .tmp17.mceContentBody .list_item .dvl-dtl dt::after {
        content: '';
        width: 2px;
        height: calc(1.4em / 1.7);
        margin-left: calc(1.2em / 1.7);
        background-color: #666;
        display: block; }
    .tmp17.mceContentBody .list_item .dvl-dtl dd {
      margin-left: calc(1.2em / 1.7);
      color: #666; }
      .tmp17.mceContentBody .list_item .dvl-dtl dd > span {
        display: inline-block; }
        .tmp17.mceContentBody .list_item .dvl-dtl dd > span:nth-last-child(n+2)::after {
          content: '、'; }
  .tmp17.mceContentBody .list_item ._lead {
    margin-top: calc(1.8em / 1.5);
    color: #666;
    font-size: calc(1.5em / 1.7); }
    .tmp17.mceContentBody .list_item ._lead + .splt {
      display: none; }

/*
#ta_ifr,
#tinymce {
	h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
		color: $siteCol-blu01;
		border: 0;
		background-color: transparent;
		position: relative;
	}
	h2 {
		font-size: calc(2.9em / 1.7);
		display: flex;
		align-items: center;
		&::before,
		&::after {
			background-color: $siteCol-l-blu01;
			display: block;
		}
		&::before {
			content: '';
			width: calc(1.2em / 2.9);
			height: calc(1.6em / 2.9);
			margin-right: calc(1.4em / 2.9);
			box-shadow: 0.4 / 2.9 + 0em 0.4 / 2.9 + 0em 0 0 #2c2c79;
			flex-shrink: 0;
			align-self: flex-start;
		}
		&::after {
			content: '';
			height: calc(1.2em / 2.9);
			margin: 0 calc(1em / 2.9) 0 calc(1.4em / 2.9);
			box-shadow: 1 / 2.9 + 0em 0.6 / 2.9 + 0em 0 0 #2c2c79;
			flex-grow: 1;
			position: relative;
			top: calc(-0.3em / 2.9);
		}
		& span {
			display: block;
		}

	}
	* + h2 {
		margin-top: 1em;
	}
	h3 {
		padding-bottom: calc(1em / 2.3);
		font-size: calc(2.3em / 1.7);
		border-bottom: solid 2px $siteCol-l-blu01;
		&::after {
			content: '';
			width: calc(18em / 2.3);
			height: 4px;
			border-radius: 100em;
			background-color: $siteCol-blu01;
			display: block;
			position: absolute;
			bottom: -3px;
			left: 0;
		}
	}
	* + h3 {
		margin-top: 1em;
	}
	h4 {
		padding-left: 0.5em;
		font-size: calc(1.9em / 1.7);
		border-left: solid 5px $siteCol-l-blu01;
	}
	* + h4 {
		margin-top: 1em;
	}
	h5 {
		font-size: 1.9 / 1.7 + 0em;
		padding-bottom: 0.4em;
		border-bottom: solid 2px rgba($siteCol-gly01, 0.15);
	}
	* + h5 {
		margin-top: 1em;
	}
	h6 {
		display: flex;
		align-items: baseline;
		&::before {
			content: '';
			width: 0.8 / 1.7 + 0em;
			height: 3px;
			margin-right: 0.5em;
			background-color: $siteCol-l-blu01;
			display: block;
			flex-shrink: 0;
			transform: translate(0, -0.29412em);
		}
	}
	* + h6 {
		margin-top: 1em;
	}
}
*/
/* ==============================================================================================
   Print styles
   ============================================================================================== */
@media print {
  *, *::before, *::after, *::first-letter, *::first-line {
    color: #000 !important;
    /* Black prints faster */
    text-shadow: none !important;
    box-shadow: none !important;
    background: transparent !important; }
  a, a:visited {
    text-decoration: underline; }
  a[href]::after {
    content: ' (" attr(href) ")'; }
  a[href^="#"]::after, a[href^="javascript:"]::after {
    content: ''; }
  abbr[title]::after {
    content: ' (" attr(title) ")'; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: solid 1px #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; } }
