// Main footer styles
// =============================================================================
.mn-foot {
	line-height: 1.5;
	background-color: $siteCol-d-blu01;
	&_inr {
		@extend %extend--inner-box-1120;
		&.-cr {
			height: 10 / 1.7 + 0em;
			display: flex;
			align-items: center;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_inr {
			&.-cr {
				flex-direction: column;
				justify-content: center;
			}
		}
	}
}

.srch-asd {
	padding-top: calc(3.5em / 1.7);
	color: $siteCol-f-wht;
	&_ttl {
		margin-bottom: calc(2em / 2.5);
		font-size: calc(2.5em / 1.7);
		text-align: center;
		.wrd-srch & {
			color: $siteCol-gly01;
			flex-shrink: 0;
		}
	}
	&_inr {
		@extend %extend--inner-box-1120;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_ttl {
			.wrd-srch & {
				margin: 0 calc(3em / 2.5) 0 0;
			}
		}
	}
}

.wrd-srch {
	@extend %extend--inner-box-960;
	margin-top: calc(4em / 1.7);
	background-color: $siteCol-gly03;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 5;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-bottom: -#{$ws-neg-val-mbl};
		padding: calc(2em / 1.5) $mbl-pad calc(2.8em / 1.5);
		flex-direction: column;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-bottom: -#{$ws-neg-val-pc};
		padding: calc(3.15em / 1.7) calc(3em / 1.7);
	}
}

.ptt {
	color: $siteCol-l-blu01;
	font: {
		size: 1.3 / 1.7 + 0em;
		weight: $fwNsrfJp-black;
	}
	text-align: center;
	background-color: $siteCol-l-blu02;
	&_lnk {
		height: calc(7.5em / 1.3);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&_lnk-txt {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		&::before {
			content: '\e90c';
			margin-bottom: calc(0.2em / 1.8);
			font: {
				family: icomoon;
				size: calc(1.8em / 1.3);
				weight: $fwNsrfJp-medium;
			}
			line-height: 1;
			display: block;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding-top: $ws-neg-val-mbl;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding-top: $ws-neg-val-pc;
	}
}

.cr-txt {
	// height: calc(6em / 1.1);
	font: {
		size: 1.1 / 1.7 + 0em;
		weight: $fwNsrfJp-bold;
	}
	text-align: center;
/*
	display: flex;
	align-items: center;
	justify-content: center;
*/
	&_lnk {
		color: $siteCol-f-wht !important;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: 1em;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-right: auto;
		order: -1;
	}
}
