// General button styles
// =============================================================================
.btn {
	width: 100%;
	max-width: calc(36em / 1.7);
	margin: 0 auto;
	color: $siteCol-f-wht !important;
	font: {
		size: calc(1.5em / 1.7);
		weight: $fwNsnsJp-bold;
	}
	line-height: 1.5;
	border: 0;
	border-radius: calc(0.3em / 1.7);
	background-color: $siteCol-blu01;
	display: block;
	&.-sns-btn,
	&.-nav-btn {
		border-radius: 0;
		background-color: transparent;
	}
	&.-sns-btn {
		width: $sns-icn-size;
		height: $sns-icn-size;
		padding: 0;
		color: $siteCol-f-wht !important;
		font-size: 1.7 / 1.5 + 0em;
		line-height: 0;
	}
	&.-nav-btn {
		max-width: calc(4.5em / 1.1);
		margin: 0 0 0 calc(2em / 1.1);
		padding: 0;
		color: $siteCol-blu01 !important;
		font: {
			size: calc(1.1em / 1.7);
			weight: $fwNsnsJp-black;
		}
		line-height: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&::before {
			font: {
				family: icomoon;
				weight: $fwNsrfJp-medium;
			}
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.mn-head & {
			color: $siteCol-f-wht !important;
		}
	}
	&.-srch-btn {
		&::before {
			height: calc(2.2em / 2);
			content: '\e904';
			font-size: calc(2em / 1.1);
		}
	}
	&.-menu-btn {
		&::before {
			height: calc(2.2em / 3.2);
			content: '\e903';
			font-size: calc(3.2em / 1.1);
		}
	}
	&.-cls-btn {
		max-width: 20 / 1.5 + 0em;
		margin-top: 1 / 1.5 + 0em;
		padding: {
			top: 3px;
			bottom: 3px;
		}
		.fxd-bnt-otr & {
			width: 20 / 1.5 + 0em;
			margin-top: 3 / 1.5 + 0em;
			padding: {
				top: 0.6 / 1.5 + 0em;
				bottom: 0.6 / 1.5 + 0em;
			}
			background-color: $siteCol-pnk01;
		}
	}
	&.-lst-btn {
		.news & {
			max-width: 22em;
			margin-top: calc(6em / 1.7);
			background-color: $siteCol-pnk01;
		}
		.top-pg-sct & {
			margin-top: calc(3em / 1.7);
		}
	}
	&_lnk,
	&_wrp {
		width: 100%;
		height: 100%;
		padding: calc(1.25em / 1.7) 1em;
		display: flex;
		align-items: center;
		justify-content: center;
		.-lst-btn & {
			.news & {
				padding: calc(0.75em / 1.7) 1em;
			}
		}
	}
	&_lnk {
		.-sns-btn & {
			padding: 0;
			display: block;
		}
		.-lst-btn & {
			&::before {
				content: '\e900';
				margin-right: calc(1.7em / 1.4);
				font: {
					family: icomoon;
					size: calc(1.4em / 1.7);
					weight: $fwNsrfJp-medium;
				}
				line-height: 1;
				display: block;
				flex-shrink: 0;
			}
		}
	}
	&_wrp {
		.-nav-btn & {
			margin-top: calc(0.3em / 1.1);
			padding: 0;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&.-nav-btn {
			margin-left: 0.8 / 1 + 0em;
			.mn-nav & {
				display: none;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		transition: opacity $overTime $easeOutQuint;
		&:hover {
			opacity: 0.4;
		}
		&.-nav-btn {
			.mn-head & {
				display: none;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		
	}
}

.tag {
	color: $siteCol-blu01;
	font: {
		size: $fz13;
		weight: $fwNsrfJp-bold;
	}
	line-height: 1;
	border-radius: calc(0.2em / 1.3);
	background-color: $siteCol-f-wht;
	display: block;
	.news & {
		color: $siteCol-blu01 !important;
		background-color: $siteCol-f-wht !important;
	}
	.mn-art & {
		color: $siteCol-f-wht;
		background-color: $siteCol-blu01;
	}
	.hr-head & {
		color: $siteCol-blu01;
		background-color: $siteCol-f-wht;
	}
	.srch-asd & {
		border-radius: 100em;
	}
	&_lnk {
		width: 100%;
		height: 100%;
		padding: calc(0.4em / 1.3) calc(0.6em / 1.3);
		display: flex;
		align-items: center;
		.srch-asd & {
			padding: calc(0.35em / 1.3) calc(1.2em / 1.3);
		}
		&::before {
			content: '';
			width: calc(0.6em / 1.3);
			height: calc(0.6em / 1.3);
			margin-right: calc(0.4em / 1.3);
			border-radius: 100em;
			background-color: currentColor;
			display: block;
			flex-shrink: 0;
			.srch-asd & {
				content: '\e902';
				width: auto;
				height: auto;
				margin-right: calc(0.6em / 1.3);
				font: {
					family: icomoon;
					size: calc(1.6em / 1.3);
					weight: $fwNsrfJp-medium;
				}
				line-height: 1;
				border-radius: 0;
				background-color: transparent;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		transition: opacity $overTime $easeOutQuint;
		&:hover {
			opacity: 0.4;
		}
	}
}

.tag-lst {
	margin: calc(-0.4em / 1.3);
	font-size: $fz13;
	display: flex;
	flex-wrap: wrap;
	.news & {
		margin-top: calc(1.2em / 1.3);
	}
	.hr-head & {
		margin-top: 0.8 / 1.3 + 0em;
	}
	.list_item & {
		margin-top: calc(1.6em / 1.3);
	}
	.srch-asd & {
		margin: calc(-0.6em / 1.3);
		justify-content: center;
	}
	&_itm {
		margin: calc(0.4em / 1.3);
		.srch-asd & {
			margin: calc(0.6em / 1.3);
		}
	}
}

.more {
	color: $siteCol-f-wht;
	font: {
		size: calc(1.5em / 1.7);
		weight: $fwNsrfJp-bold;
	}
	line-height: 1.4;
	border-radius: 100em;
	background-color: $siteCol-blu01;
	overflow: hidden;
	display: inline-flex;
	.list_item & {
		margin-left: 1em;
		flex-shrink: 0;
	}
	a {
		width: 100%;
		height: 100%;
		padding: calc(0.4em / 1.5) 1em;
		display: flex;
		align-items: center;
		&::after {
			content: '\e902';
			margin-left: calc(1.8em / 1.6);
			font: {
				family: icomoon;
				size: calc(1.6em / 1.5);
				weight: $fwNsrfJp-medium;
			}
			line-height: 1;
			display: block;
			flex-shrink: 0;
		}
	}
	@include mediaQueries-maxW('mbl-m02') { // mobile size = M02 //
		.list_item & {
			width: 136px;
			margin: 0.6em 0 0;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		transition: opacity $overTime $easeOutQuint;
		&:hover {
			opacity: 0.4;
		}
	}
}

.tgl {
	padding: calc(0.25em / 1.5) 1em;
	color: $siteCol-f-wht;
	font: {
		size: calc(1.5em / 1.7);
		weight: $fwNsrfJp-bold;
	}
	font-size: calc(1.5em / 1.7);
	line-height: 1.4;
	border-radius: 100em;
	background-color: $siteCol-blu01;
	display: inline-flex;
	flex-shrink: 0;
	align-items: center;
	cursor: pointer;
	.intrdct & {
		margin-left: calc(2em / 1.5);
	}
	.is-shw & {
		&::after {
			content: attr(data-tgl);
		}
	}
	&_icn {
		width: calc(1.3em / 1.5);
		height: calc(0.3em / 1.5);
		margin-right: calc(1em / 1.5);
		line-height: 1;
		background-color: currentColor;
		display: block;
		flex-shrink: 0;
		position: relative;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			background-color: inherit;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			transform: rotate(-90deg);
			transition: transform $tglTime $easeOutQuint;
			.is-shw & {
				transform: rotate(0);
			}
		}
	}
	&_txt {
		.is-shw & {
			display: none;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		.intrdct & {
			width: 75%;
			margin: calc(2em / 1.5) auto 0;
			display: flex;
			justify-content: center;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		transition: opacity $overTime $easeOutQuint;
		&:hover {
			opacity: 0.4;
		}
	}
}
