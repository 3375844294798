// Main content general styles
// =============================================================================
.mn-cnt {
	background: {
		color: $siteCol-f-wht;
		image: imgUrl('img_bg_noise.png');
		position: left top;
		size: 50px 50px;
	}
	.cnt-sub & {
		h2, h3 {
			color: $siteCol-blu01;
		}
		h2 {
			font-size: 2.9 / 1.7 + 0em;
		}
		h3 {
			font-size: 2.3 / 1.7 + 0em;
		}
		* + p {
			margin-top: 0.5em;
		}
		ul, ol {
			counter-reset: counter-li;
			&:not(.tab-lst):not(.tag-lst) {
				& > li {
					position: relative;
					counter-increment: counter-li;
					&:nth-child(n+2) {
						margin-top: 0.5em;
					}
					&::before {
						display: inline-block;
						position: absolute;
						top: 0;
						left: 0;
					}
					&[data-lst-styl] {
						&::before {
							content: attr(data-lst-styl);
						}
					}
				}
			}
		}
		ul {
			&:not(.tab-lst):not(.tag-lst) {
				& > li {
					padding-left: 1em;
					&::before {
						content: '・';
					}
				}
			}
		}
		ol {
			&:not(.tab-lst):not(.tag-lst) {
				& > li {
					$base-pdng-l: '3.6em / 1.7';
					padding-left: calc(#{$base-pdng-l});
					&::before {
						content: counter(counter-li)'．';
						width: calc(#{$base-pdng-l});
						text-align: right;
					}
				}
			}
		}
		* + ul,
		* + ol {
			&:not(.tab-lst):not(.tag-lst) {
				margin-top: 0.5em;
			}
		}
		.list_item {
			&:nth-child(n+2) {
				margin-top: calc(3em / 1.7);
				padding-top: calc(3em / 1.7);
				border-top: solid 2px rgba($siteCol-blk01, 0.2);
			}
			h2 {
				margin-right: auto;
				font-size: calc(2.3em / 1.7);
			}
			time {
				font-size: 1.9 / 2.5 + 0em;
				display: block;
			}
			& > div {
				&:first-child {
					display: flex;
					align-items: center;
				}
			}
		}
		.intrdct {
			border-radius: calc(0.5em / 1.7);
			background-color: $siteCol-f-wht;
			&_head {
				display: flex;
				align-items: center;
			}
			&_ttl {
				margin-right: auto;
				color: inherit !important;
				font-size: calc(2.3em / 1.7) !important;
				cursor: pointer;
			}
			&_acrd {
				display: none;
			}
			ol {
				margin-top: calc(3.5em / 1.5) !important;
				padding: calc(2em / 1.5);
				font-size: calc(1.5em / 1.7);
				border-radius: calc(0.5em / 1.5);
				background-color: rgba($siteCol-gly05, 0.25);
				& > li {
					$base-pdng-l: '2.2em / 1.7';
					padding-left: calc(#{$base-pdng-l} + 1.3em / 1.7) !important;
					&::before {
						content: '('counter(counter-li)')' !important;
						width: calc(#{$base-pdng-l}) !important;
					}
				}
			}
		}
	}
	.edit_content {
		h2, h3, h4, h5, h6 {
			margin: 0;
			padding: 0;
			color: $siteCol-blu01;
			border: 0;
			background-color: transparent;
			position: relative;
		}
		h2 {
			.tmp11 &,
			.tmp22 &,
			.tmp21 & {
				font-size: calc(2.9em / 1.7);
				display: flex;
				align-items: center;
				&::before,
				&::after {
					background-color: $siteCol-l-blu01;
					display: block;
				}
				&::before {
					content: '';
					width: calc(1.2em / 2.9);
					height: calc(1.6em / 2.9);
					margin-right: calc(1.4em / 2.9);
					box-shadow: 0.4 / 2.9 + 0em 0.4 / 2.9 + 0em 0 0 #2c2c79;
					flex-shrink: 0;
					align-self: flex-start;
				}
				&::after {
					content: '';
					height: calc(1.2em / 2.9);
					margin: 0 calc(1em / 2.9) 0 calc(1.4em / 2.9);
					box-shadow: 1 / 2.9 + 0em 0.6 / 2.9 + 0em 0 0 #2c2c79;
					flex-grow: 1;
					position: relative;
					top: calc(-0.3em / 2.9);
				}
				& span {
					display: block;
				}
			}
		}
		* + h2 {
			.tmp11 &,
			.tmp22 &,
			.tmp21 & {
				margin-top: 1em;
			}
		}
		h3 {
			.tmp11 &,
			.tmp22 &,
			.tmp21 & {
				padding-bottom: calc(1em / 2.3);
				font-size: calc(2.3em / 1.7);
				border-bottom: solid 2px $siteCol-l-blu01;
				&::after {
					content: '';
					width: calc(18em / 2.3);
					height: 4px;
					border-radius: 100em;
					background-color: $siteCol-blu01;
					display: block;
					position: absolute;
					bottom: -3px;
					left: 0;
				}
			}
		}
		* + h3 {
			.tmp11 &,
			.tmp22 &,
			.tmp21 & {
				margin-top: 1em;
			}
		}
		h4 {
			.tmp11 &,
			.tmp22 &,
			.tmp21 & {
				padding-left: 0.5em;
				font-size: calc(1.9em / 1.7);
				border-left: solid 5px $siteCol-l-blu01;
			}
			.tmp4 & ,
			.tmp8 & {
				font-size: calc(2.9em / 1.7);
				display: flex;
				align-items: center;
				&::before,
				&::after {
					background-color: $siteCol-l-blu01;
					display: block;
				}
				&::before {
					content: '';
					width: calc(1.2em / 2.9);
					height: calc(1.6em / 2.9);
					margin-right: calc(1.4em / 2.9);
					box-shadow: 0.4 / 2.9 + 0em 0.4 / 2.9 + 0em 0 0 #2c2c79;
					flex-shrink: 0;
					align-self: flex-start;
				}
				&::after {
					content: '';
					height: calc(1.2em / 2.9);
					margin: 0 calc(1em / 2.9) 0 calc(1.4em / 2.9);
					box-shadow: 1 / 2.9 + 0em 0.6 / 2.9 + 0em 0 0 #2c2c79;
					flex-grow: 1;
					position: relative;
					top: calc(-0.3em / 2.9);
				}
				& span {
					display: block;
				}
			}
		}
		* + h4 {
			.tmp11 &,
			.tmp22 &,
			.tmp21 & {
				margin-top: 1em;
			}
			.tmp4 &,
			.tmp8 & {
				margin-top: calc(5.5em / 2.9);
			}
		}
		h5 {
			.tmp11 &,
			.tmp22 &,
			.tmp21 & {
				font-size: 1.9 / 1.7 + 0em;
				padding-bottom: 0.4em;
				border-bottom: solid 2px rgba($siteCol-gly01, 0.15);
			}
			.tmp4 &,
			.tmp8 & {
				padding-bottom: calc(1em / 2.3);
				font-size: calc(2.3em / 1.7);
				border-bottom: solid 2px $siteCol-l-blu01;
				&::after {
					content: '';
					width: calc(18em / 2.3);
					height: 4px;
					border-radius: 100em;
					background-color: $siteCol-blu01;
					display: block;
					position: absolute;
					bottom: -3px;
					left: 0;
				}
			}
		}
		* + h5 {
			.tmp11 &,
			.tmp22 &,
			.tmp21 & {
				margin-top: 1em;
			}
			.tmp4 &,
			.tmp8 & {
				margin-top: calc(4em / 2.3);
			}
		}
		h6 {
			.tmp11 &,
			.tmp22 &,
			.tmp21 & {
				display: flex;
				align-items: baseline;
				&::before {
					content: '';
					width: 0.8 / 1.7 + 0em;
					height: 3px;
					margin-right: 0.5em;
					background-color: $siteCol-l-blu01;
					display: block;
					flex-shrink: 0;
					transform: translate(0, #{-0.5 / 1.7 + 0em});
				}
			}
		}
		* + h6 {
			.tmp11 &,
			.tmp22 &,
			.tmp21 & {
				margin-top: 1em;
			}
		}
		hr {
			height: 0px;
			border-top: 2px dashed rgba($siteCol-blk01, 0.2);
		}
		* + hr {
			margin: 1.5em 0;
		}
		p {
			margin: 0;
			font-size: 1em;
		}
		* + p {
			margin-top: 0.5em;
		}
		ul {
			padding: 0;
		}
		ol {
			.tmp11 &,
			.tmp22 &,
			.tmp21 & {
				li {
					padding-left: 3.6 / 1.7 + 0em !important;
				}
			}
		}
		* + ul,
		* + ol {
			margin-top: 0.5em !important;
		}
		li {
			margin: 0;
			padding: 0 !important;
			list-style: none;
			&::before {
				display: none !important;
			}
			.tmp11 &,
			.tmp22 &,
			.tmp21 & {
				margin-top: 0 !important;
				padding-left: 1em !important;
				&::before {
					content: '・';
					display: block !important;
					position: relative;
					top: 0;
					left: 0;
				}
			}
		}
		table {
			margin-top: 0.5em;
			padding: calc(1.5em / 1.7);
			line-height: 1.5;
			background-color: $siteCol-f-wht;
			&, tbody,
			tr, th, td {
				display: block;
			}
			tr, th, td {
				display: flex;
			}
			th, td {
				width: auto;
				padding: 0.5em 0.8em 0.5em 0.5em;
				background-color: $siteCol-gly06;
				align-items: center;
			}
			tr {
				&:nth-child(n+2) {
					margin-top: calc(1em / 1.7);
				}
			}
			img {
				height: auto !important;
				max-width: calc(4em / 1.7);
				min: {
					width: 0;
					height: 0;
				}
				margin: 0 0.8em 0 0;
				border: 0;
				flex-shrink: 0;
			}
		}
		.re-tbl {
			padding: 0;
			font-size: 1.5 / 1.7 + 0em;
			border: {
				collapse: separate;
				spacing: 5px;
			}
			display: table;
			tbody {
				display: table-row-group;
			}
			tr {
				display: table-row;
				&:nth-child(n+2) {
					margin-top: auto;
				}
			}
			th, td {
				width: auto;
				padding: 0.4em 0.6em;
				border-radius: 0.5 / 1.7 + 0em;
				display: table-cell;
			}
			th {
				background-color: #d8d8d8;
			}
			img {
				max-width: none;
				margin: 0;
			}
		}
		.tbl-scrl {
			overflow: scroll;
			-webkit-overflow-scrolling: touch;
			overflow-scrolling: touch;
			scrollbar-width: none;
			-ms-overflow-style: none;
			&::-webkit-scrollbar {
				display: none;
			}
			& > table {
				min-width: 730px;
			}
		}
	}
	.list_box {
		.tmp17 & {
			margin-top: calc(4.5em / 1.7);
		}
	}
	.list_item {
		.tmp17 & {
			.ttl-box {
				margin-right: auto;
			}
			.dvl-dtl {
				margin-top: calc(0.8em / 1.7);
				font-weight: $fwNsnsJp-bold;
				line-height: 1.5;
				& > div {
					display: flex;
					align-items: flex-start;
				}
				dt {
					display: flex;
					align-items: center;
					&::after {
						content: '';
						width: 2px;
						height: calc(1.4em / 1.7);
						margin-left: calc(1.2em / 1.7);
						background-color: $siteCol-gly01;
						display: block;
					}
				}
				dd {
					margin-left: calc(1.2em / 1.7);
					color: $siteCol-gly01;
					& > span {
						display: inline-block;
						&:nth-last-child(n+2) {
							&::after {
								content: '、';
							}
						}
					}
				}
			}
			._lead {
				margin-top: calc(1.8em / 1.5);
				color: $siteCol-gly01;
				font-size: calc(1.5em / 1.7);
				& + .splt {
					display: none;
				}
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		.cnt-sub & {
			.intrdct {
				padding: 0 $mbl-pad;
				&_head {
					padding: $mbl-pad 0;
				}
				&_acrd {
					padding-bottom: $mbl-pad;
				}
			}
			.list_item {
				
			}
		}
		.edit_content {
			table {
				th, td {
					&:nth-child(n+2) {
						margin-top: calc(1em / 1.7);
					}
				}
				tr {
					display: block;
				}
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		.cnt-sub & {
			.intrdct {
				&_head {
					display: block;
				}
			}
		}
	}
	@include mediaQueries-maxW('mbl-m02') { // mobile size = M02 //
		.cnt-sub & {
			.list_item {
				& > div {
					&:first-child {
						display: block;
					}
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		.cnt-sub & {
			.intrdct {
				padding: 0 $tblt-pad;
				&_head {
					padding: $tblt-pad 0;
				}
				&_acrd {
					padding-bottom: $tblt-pad;
				}
			}
		}
		.edit_content {
			table {
				th, td {
					width: calc((100% - 1.5em / 1.7 * 2) / 3);
					&:nth-child(n+2) {
						margin-left: calc(1.5em / 1.7);
					}
				}
			}
		}
		.list_box {
			.tmp12 & {
				display: flex;
				flex-wrap: wrap;
			}
		}
		.list_item {
			.tmp12 & {
				width: calc((100% - #{$tblt-pad}) / 2);
				&:nth-child(2) {
					margin-top: 0;
					padding-top: 0;
					border-top: 0;
				}
				&:nth-child(even) {
					margin-left: $tblt-pad;
				}
			}
		}
	}
}

.mn-art {
	padding: calc(6em / 1.7) 0 calc(8em / 1.7);
	.cnt-sub & {
		padding-top: 0;
	}
	.tmp9 &, .tmp10 &,
	.tmp11 &, .tmp22 &,
	.pg100 &, .pg1827 & {
		padding-bottom: 0;
	}
	&_inr {
		@extend %extend--inner-box-1220;
		.cnt-sub & {
			padding-top: calc(3.5em);
		}
		.pg100 &,
		.pg1827 & {
			display: none;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_inr {
			.cnt-top & {
				display: flex;
			}
		}
	}
}

.hr-head {
	color: $siteCol-f-wht;
	background: {
		color: $siteCol-blu01;
		image: imgUrl('img_bg_hrh.jpg');
		position: center;
		size: cover;
	}
	padding: calc(3.5em / 1.7) 0;
	position: relative;
	&::after {
		content: '';
		width: 100%;
		height: 100%;
		background: {
			color: rgba($siteCol-k-blk, 0.2);
			image: imgUrl('img_bg_dot.svg');
			position: left top;
			size: 4px 4px;
		}
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
	}
	&_inr {
		@extend %extend--inner-box-1220;
	}
	&_ttl {
		font-size: calc(3.5em / 1.7);
		display: flex;
		flex-direction: column;
	}
	&_ttl-prm {
		margin-top: calc(1.2em / 3.5);
	}
	&_ttl-scnd {
		font-size: calc(2.1em / 3.5);
		&.-en {
			font-size: calc(1.7em / 3.5);
		}
	}
	&_ttl-dt {
		font-size: calc(1.7em / 3.5);
	}
	&_dpy {
		display: inline-flex;
		align-items: center;
		&::after {
			content: '';
			width: 2px;
			height: 0.7em;
			margin: 0 0.5em;
			background-color: currentColor;
			display: block;
		}
	}
	&_nts {
		margin-top: calc(2.6em / 1.5) !important;
		font: {
			size: calc(1.5em / 1.7);
			weight: $fwNsnsJp-bold;
		}
	}
	& > * {
		position: relative;
		z-index: 5;
	}
	.tmp9 & ,
	.tmp10 & {
		&_ttl-prm {
			margin-top: calc(0.5em / 3.5);
		}
		&_ttl-scnd {
			&.-dvlp {
				margin-top: calc(1.2em / 1.9);
			}
		}
	}
	.tmp11 &,
	.tmp22 & {
		&_ttl-prm {
			margin-top: 0;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_flx {
			display: flex;
			justify-content: space-between;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_flx {
			display: block;
		}
	}
}

.author {
	width: auto;
	font-size: 1.5 / 1.7 + 0em;
	line-height: 1.5;
	flex-shrink: 0;
	tr {
		vertical-align: top;
		&:nth-child(n+2) {
			th, td {
				padding-top: 0.5em;
			}
		}
	}
	& ._name {
		// min-width: 7em;
		font-weight: $fwNsnsJp-bold;
	}
	& ._group {
		max-width: calc(12em + #{3 / 1.5 + 0em});
		padding-left: 3 / 1.5 + 0em;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		width: 100%;
		margin-top: 2.4 / 1.3 + 0em;
		& ._name {
			width: 7em;
		}
		& ._group {
			width: 80%;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-left: $tblt-pad;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		width: 100%;
		margin: 2.6 / 1.5 + 0em 0 0;
		& ._name {
			width: 7em;
		}
		& ._group {
			width: 80%;
		}
	}
}

.his-sct {
	& + * {
		margin-top: 2.4em;
	}
	h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
		color: $siteCol-blu01;
		border: 0;
		background-color: transparent;
		position: relative;
	}
	h2 {
		font-size: calc(2.9em / 1.7);
		display: flex;
		align-items: center;
		&::before,
		&::after {
			background-color: $siteCol-l-blu01;
			display: block;
		}
		&::before {
			content: '';
			width: calc(1.2em / 2.9);
			height: calc(1.6em / 2.9);
			margin-right: calc(1.4em / 2.9);
			box-shadow: 0.4 / 2.9 + 0em 0.4 / 2.9 + 0em 0 0 #2c2c79;
			flex-shrink: 0;
			align-self: flex-start;
		}
		&::after {
			content: '';
			height: calc(1.2em / 2.9);
			margin: 0 calc(1em / 2.9) 0 calc(1.4em / 2.9);
			box-shadow: 1 / 2.9 + 0em 0.6 / 2.9 + 0em 0 0 #2c2c79;
			flex-grow: 1;
			position: relative;
			top: calc(-0.3em / 2.9);
		}
		& span {
			display: block;
		}
	}
	* + h2 {
		margin-top: 1em;
	}
	h3 {
		padding-bottom: calc(1em / 2.3);
		font-size: calc(2.3em / 1.7);
		border-bottom: solid 2px $siteCol-l-blu01;
		&::after {
			content: '';
			width: calc(18em / 2.3);
			height: 4px;
			border-radius: 100em;
			background-color: $siteCol-blu01;
			display: block;
			position: absolute;
			bottom: -3px;
			left: 0;
		}
	}
	* + h3 {
		margin-top: 1em;
	}
	h4 {
		padding-left: 0.5em;
		font-size: calc(1.9em / 1.7);
		border-left: solid 5px $siteCol-l-blu01;
	}
	* + h4 {
		margin-top: 1em;
	}
	h5 {
		font-size: 1.9 / 1.7 + 0em;
		padding-bottom: 0.4em;
		border-bottom: solid 2px rgba($siteCol-gly01, 0.15);
	}
	* + h5 {
		margin-top: 1em;
	}
	h6 {
		display: flex;
		align-items: baseline;
		&::before {
			content: '';
			width: 0.8 / 1.7 + 0em;
			height: 3px;
			margin-right: 0.5em;
			background-color: $siteCol-l-blu01;
			display: block;
			flex-shrink: 0;
			transform: translate(0, #{-0.5 / 1.7 + 0em});
		}
	}
	* + h6 {
		margin-top: 1em;
	}
	hr {
		height: 0px;
		border-top: 2px dashed rgba($siteCol-blk01, 0.2);
	}
	* + hr {
		margin: 1em 0;
	}
	ul, ol {
		& > li {
			padding: 1em;
			background-color: $siteCol-f-wht;
			&::before {
				top: 1em !important;
				left: 1em !important;
			}
		}
	}
	ul {
		& > li {
			padding-left: 2em !important;
		}
	}
	ol {
		& > li {
			$base-pdng-l: '3.6em / 1.7 + 1em';
			padding-left: calc(#{$base-pdng-l}) !important;
			&::before {
				width: calc(#{$base-pdng-l});
			}
		}
	}
}

.ppr-sct {
	padding: 1.8em;
	border-radius: 5px;
	background-color: $siteCol-f-wht;
	& + * {
		margin-top: 2.4em;
	}
	hr:not(.splt) {
		height: 0px;
		border-top: 2px dashed rgba($siteCol-blk01, 0.2);
	}
	* + hr {
		margin: 1em 0;
	}
}

.figure_box {
	max-width: 680px;
	margin: 0 auto;
	text-align: center;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	&.-mov {
		margin-top: 25 / 17 + 0em;
	}
	* + & {
		margin-top: 2.35em;
	}
	.his-sct & {
		margin-top: 1em;
	}
	figure {
		padding: 1.2em;
		border-radius: 5px;
		background-color: rgba($siteCol-gly05, 0.6);
	}
	figcaption {
		margin-top: 1.2em;
		color: $siteCol-brn04;
		font: {
			size: 1.5 / 1.7 + 0em;
			weight: $fwNsnsJp-bold;
		}
	}
	img {
		width: 100%;
	}
}

.tab {
	@extend %extend--inner-box-1220;
	margin-top: calc(2em / 1.7);
	padding: calc(0.8em / 1.7) 0;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	scrollbar-width: none;
	-ms-overflow-style: none;
	position: relative;
	z-index: 5;
	&::-webkit-scrollbar {
		display: none;
	}
}

.tab-lst {
	font-weight: $fwNsnsJp-bold;
	line-height: 1.4;
	display: flex;
	&::before,
	&::after {
		content: '';
		display: block;
		flex-shrink: 0;
	}
	&_itm {
		overflow: hidden;
		border: solid 1px $siteCol-f-wht;
		border-radius: 2px;
		background-color: rgba($siteCol-f-wht, 0.15);
		flex-shrink: 0;
		transition:
			color $overTime $easeOutQuint,
			background-color $overTime $easeOutQuint;
		&:nth-child(n+2) {
			margin-left: calc(0.5em / 1.7);
		}
		&.is-actv {
			color: $siteCol-blu01;
			background-color: rgba($siteCol-f-wht, 1);
		}
	}
	&_lnk {
		padding: 0.5em 1em;
		display: block;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&::before,
		&::after {
			width: $mbl-pad;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: calc(1.3em / 1.5);
		&_lnk {
			padding: calc(1em / 1.3) calc(1.8em / 1.3);
			display: block;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&::before,
		&::after {
			width: $pc-pad;
		}
		&_itm {
			&:hover {
				color: $siteCol-blu01;
				background-color: rgba($siteCol-f-wht, 1);
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&::before,
		&::after {
			width: $tblt-pad;
		}
	}
}

._cate {
	margin-bottom: 0.5 / 1.5 + 0em;
	padding-left: 0.8 / 1.5 + 0em;
	color: $siteCol-f-wht;
	font: {
		size: 1.5 / 2.5 + 0em;
		weight: $fwNsnsJp-bold;
	}
	line-height: 1;
	border-left: solid 0.3 / 1.5 + 0em currentColor;
	display: block;
	&._news {
		color: #2d88ea;
		&::before {
			content: 'ROBOGAKU';
		}
	}
	&._rij {
		color: $siteCol-l-blu01;
		&::before {
			content: 'LABORATORY';
		}
	}
	&._history {
		color: #13d0ff;
		&::before {
			content: 'HISTORY';
		}
	}
}

.no-rslt {
	& + .prev_next {
		display: none;
	}
}

.prev_next {
	margin-bottom: 3em;
	padding-top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	
	
	span {
		a {
  		color: #fff;
			background-color: $siteCol-blu01;
		}
	}
	& > span {
  	margin-bottom: 5px;
		&:nth-child(n+2) {
			margin-left: 5px;
		}
		& > * {
			min-width: 3em;
			height: 2.7em;
			padding: 0.5em !important;
			display: flex !important;
			align-items: center;
			justify-content: center;
			background-color: #ccc;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		& > span {
			& > * {
// 				width: 5em;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		span {
			a {
				transition: opacity $overTime $easeOutQuint;
				&:hover {
					opacity: 0.4;
				}
			}
		}
	}
}

.cnt-sub .mn-cnt section + .prev_next { margin-top: 3em !important; }

.nts {
	margin-bottom: 3 / 1.7 + 0em !important;
	padding: 0.8em 1em;
	border: solid 2px $siteCol-pnk01;
	border-radius: 3px;
	background-color: $siteCol-f-wht;
	* + & {
		margin: 0.5em 0 !important;
	}
}

.fbox {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	.figure_box{
		width: calc(50% - 20px);
		background-color: rgba($siteCol-gly05, 0.6);
		// margin: 10px;
		// padding: 10px;
		// border: 1px solid gray;
	}
	figure {
		background-color: transparent;
	}
}

.splt {
	width: calc(4em / 1.7);
	margin: calc(2.5em / 1.7) auto;
	height: 3px;
	background: {
		color: $siteCol-blu01;
		image: linear-gradient(
			90deg,
			rgba($siteCol-ash-blu01, 1) 0%,
			rgba($siteCol-ash-blu01, 1) 50%,
			rgba($siteCol-blu01, 1) 50%,
			rgba($siteCol-blu01, 1) 100%
		);
	}
	.news &,
	.hr-head & {
		display: none;
	}
	.ppr-sct & {
		margin: 0.8em 0 0;
		.ua-ie & {
			margin-right: auto;
		}
	}
	.list_item & {
		margin: calc(1.5em / 1.7) 0 0;
		.ua-ie & {
			margin-right: auto;
		}
	}
	.ajaxSearch_result & {
		margin: 0.8 / 1.7 + 0em 0 1 / 1.7 + 0em;
		.ua-ie & {
			margin-right: auto;
		}
	}
}

#mainContainer {
	min-width: 0 !important;
}

.pdf-js-unt {
	width: 100%;
	aspect-ratio: 1 / 1.414;
	border: 0;
}